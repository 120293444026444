import React, {useLayoutEffect} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import 'swiper/css/pagination';

import 'bootstrap/dist/css/bootstrap-grid.css';
import dsnEffect, {fade, moveSection, textAnimation} from "./animation/DsnEffect";
import Layout from "./layout/Layout";
import MainDemo from "./views/home/MainDemo";
import {useLocation} from "react-router-dom"; // Affiliate program image path

const MultyncomeLanding = () => {
    const location = useLocation(); // Check current route

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        dsnEffect.registerEffect(moveSection, fade, textAnimation);

        gsap.config({
            nullTargetWarn: false
        });
        gsap.defaults({
            ease: "none",
            duration: 1,
            overwrite: "auto",
        });

        // Dynamically load style.scss only on the main demo page
        if (location.pathname === "/") { // Replace "/main-demo" with the actual route
            import("./assets/sass/style.scss");
        }
    }, [location.pathname]);
    return (

        <Layout className="v-dark background-main" tag="main">
            <MainDemo/>
        </Layout>


    );
};

export default MultyncomeLanding;
