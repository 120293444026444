import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaMedal } from "react-icons/fa"; // Using react-icons for the medal icon
import RequestEngine from "../../core/RequestEngine"; // Assuming RequestEngine is in this path

// Container for the entire rank screen
const RanksContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
`;

// Grid layout for rank cards
const RankGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr; /* Single column */
    gap: 20px;
`;

// Card style for each rank
const RankCard = styled.div`
    background-color: #1c1c28;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 10px rgba(51, 255, 153, 0.7);
    }

    &:hover .rank-description {
        max-height: 100px; /* Max height of the description when hovered */
        opacity: 1;
        padding-top: 10px;
    }
`;

// Badge style for the "You are here" indicator
const Badge = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff9f1c;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 20px;
    text-transform: uppercase;
`;

// Icon for the rank
const RankIcon = styled.div`
    background-color: #000; /* Set background to black */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #33ff99;
    font-size: 24px;

    /* Glowing effect */
    box-shadow: 0 0 5px 2px #33ff99, 0 0 20px 4px #33ff99, 0 0 30px 6px #33ff99;
`;

const RankDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

const RankTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: white;
`;

const RankDescription = styled.div`
    font-size: 14px;
    color: #cccccc;
    margin-top: 5px;
    max-height: 0; /* Hidden by default */
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s ease;
`;

const RanksScreen = () => {
    const [ranks, setRanks] = useState([]); // State to store the rank data
    const [loading, setLoading] = useState(true); // Loading state

    // Fetch rank data from the API
    useEffect(() => {
        const fetchRanks = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/ranks");
                if (response && response.status === 200 && response.data) {
                    setRanks(response.data.data); // Store the fetched rank data
                } else {
                    console.error("Failed to fetch ranks:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching ranks:", error);
            } finally {
                setLoading(false); // Stop the loading spinner
            }
        };

        fetchRanks(); // Call the function to fetch rank data
    }, []);

    if (loading) {
        return (
            <RanksContainer>
                <h2>Loading Ranks...</h2>
            </RanksContainer>
        );
    }

    return (
        <RanksContainer>
            <h2>Your Ranks</h2>
            <RankGrid>
                {ranks.map((rank, index) => (
                    <RankCard key={index}>
                        {/* Add badge to the first rank */}
                        <RankIcon>
                            <img style={{width: 52,
                                overflow: "hidden",
                                borderRadius: "50%"}} src={rank.fullpicture} />
                        </RankIcon>
                        <RankDetails>
                            <RankTitle>{rank.title}</RankTitle>
                            <RankDescription className="rank-description">
                                {rank.description}
                            </RankDescription>
                        </RankDetails>
                    </RankCard>
                ))}
            </RankGrid>
        </RanksContainer>
    );
};

export default RanksScreen;
