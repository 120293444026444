import React from 'react';
import NextPage from "./NextPage";

function NextPageContent({...restProps}) {
    return (
        <NextPage to="/signup"  text="Your funds are held securely with a regulated broker, not us. For investments of $50K and above, enjoy the flexibility of customizing your experience." {...restProps}>
          Secure Investments
        </NextPage>
    );
}

export default NextPageContent;