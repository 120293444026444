import React from 'react';
import {dsnCN} from "../../hooks/helper";
import { CloseButton } from 'react-bootstrap';

function Copyright({className, ...restProps}) {
    return (
        <div className={dsnCN('copyright', className)} {...restProps}>
            <div className="text-center">
                <p>© {new Date().getFullYear()}2024 Multyncome. All rights reserved.y</p>
                <div className="copyright-text over-hidden" style={{ color: "#33ff99" }}>
                Disclaimer | Privacy Policy 
                    
                </div>
            </div>
        </div>
    );
}

export default Copyright;