import React, { useMemo, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../App.css";
import { useTable, useSortBy } from "react-table";
import RequestEngine from "../../core/RequestEngine";
import TopBar from "./TopBar";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from "chart.js";
import { Utilites } from "../../core/Utilites";
import { Memory } from "../../core/Memory";

// Register the Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// Styled Components
const VaultSection = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const VaultCard = styled.div`
    background: black;
    border: 1px solid #3b3d3b;
    border-radius: 10px;
    padding: 20px;
`;

const TableContainer = styled.div`
    margin-top: 20px;
    background-color: #1c1c28;
    border-radius: 10px;
    padding: 20px;
    color: #fff;

    table {
        width: 100%;
        border-spacing: 0;
        thead {
            background-color: #393b54;
        }
        th,
        td {
            padding: 10px;
            border-bottom: 1px solid #2e2f45;
            text-align: left;
        }

        th {
            cursor: pointer;
        }
    }
`;

const NewsCarousel = styled(Slider)`
    .slick-prev:before,
    .slick-next:before {
        color: white;
    }

    .slick-slide {
        padding: 10px;
        outline: none;
    }
`;

const NewsItem = styled.div`
    display: flex;
    align-items: center;
    background-color: #050505;
    margin: 5px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    img {
        width: 320px;
        height: 200px;
        margin-right: 20px;
        border-radius: 10px;
        padding: 10px;
        object-fit: cover;
    }
`;

const glowingEffect = keyframes`
    0% {
        text-shadow: 0 0 5px red, 0 0 10px red, 0 0 15px red, 0 0 20px red;
    }
    50% {
        text-shadow: 0 0 10px red, 0 0 20px red, 0 0 30px red, 0 0 40px red;
    }
    100% {
        text-shadow: 0 0 5px red, 0 0 10px red, 0 0 15px red, 0 0 20px red;
    }
`;

const GlowingRedText = styled.div`
    font-size: 16px;
    color: red;
    animation: ${glowingEffect} 1.5s ease-in-out infinite;
    text-align: center;
    margin-bottom: 10px;
`;

const GreenWelcomeText = styled.div`
    font-size: 16px;
    color: #33ff99;
    text-align: center;
    margin-bottom: 20px;
`;

const NewsDescription = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

const NewsTitle = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
`;

// Utility function to strip HTML tags
const stripHtmlTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
};

const Dashboard = () => {
    const [newsData, setNewsData] = useState([]);
    const [referralList, setReferralList] = useState([]);
    const [userActivity, setUserActivity] = useState([]);
    const [loading, setLoading] = useState(true);
    const [accountBalance, setAccountBalance] = useState("0");
    const [dailyprofit, setDailyprofit] = useState("0");
    const [currentCredits, setCurrentCredits] = useState("0");
    const [referralChartData, setReferralChartData] = useState({});
    const [referral, setReferral] = useState("");
    const [firstname, setFirstname] = useState("");
    const [userName, setUserName] = useState(""); // For the user's name

    const [rank, setRank] = useState("Unranked");
    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setReferral(user.referral);
            setFirstname(user.first_name);

            setRank(user?.rank?.title || "Unranked");
            setUserName(user.username); // Fetch the username
        };
        fetchData();
    }, []);

    // Fetch data from the "user/home" endpoint using RequestEngine
    const fetchData = async () => {
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem("user/home");
            if (response && response.status === 200 && response.data.data) {
                const overview = response.data.data.overview;

                if (overview) {
                    setAccountBalance(overview.accountBalance);
                    setDailyprofit(overview.dailyprofit);
                    setCurrentCredits(overview.currentCredits);
                }

                const fetchedNews = response.data.data.news.map((newsItem) => ({
                    title: newsItem.title,
                    image: newsItem.fullpicture,
                    description: stripHtmlTags(newsItem.description),
                }));
                setNewsData(fetchedNews);

                const fetchedReferralList = response.data.data.referrallist
                    .map((referral) => ({
                        username: referral.username,
                        date: new Date(referral.createdAt).toLocaleDateString(),
                    }))
                    .slice(0, 3);
                setReferralList(fetchedReferralList);

                const referralCountByMonth = getReferralCountByMonth(response.data.data.referrallist);
                setReferralChartData(referralCountByMonth);

                const fetchedUserActivity = response.data.data.useractivity.map((activityItem) => ({
                    title: activityItem.name,
                    date: new Date(activityItem.createdAt).toLocaleDateString(),
                    amount: activityItem.amount,
                }));
                setUserActivity(fetchedUserActivity);
            } else {
                console.error("Failed to fetch data:", response.data.message);
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    // Get referral count by month for the past 12 months
    const getReferralCountByMonth = (referralList) => {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        const months = [];
        const memberCounts = new Array(12).fill(0);
        const nonMemberCounts = new Array(12).fill(0);

        for (let i = 0; i < 12; i++) {
            const month = (currentMonth - i + 12) % 12;
            const year = month > currentMonth ? currentYear - 1 : currentYear;
            const monthName = new Date(year, month).toLocaleString("default", { month: "short" });
            months.unshift(monthName);

            referralList.forEach((referral) => {
                const referralDate = new Date(referral.createdAt);
                if (referralDate.getMonth() === month && referralDate.getFullYear() === year) {
                    if (referral.isMember) {
                        memberCounts[11 - i]++;
                    } else {
                        nonMemberCounts[11 - i]++;
                    }
                }
            });
        }

        return {
            labels: months,
            datasets: [
                {
                    label: "Member Referrals",
                    data: memberCounts,
                    borderColor: "#33FF99",
                    backgroundColor: "#33FF99",
                    fill: false,
                    tension: 0.4,
                },
                {
                    label: "Non-Member Referrals",
                    data: nonMemberCounts,
                    borderColor: "#FF6347",
                    backgroundColor: "#FF6347",
                    fill: false,
                    tension: 0.4,
                },
            ],
        };
    };

    useEffect(() => {
        Utilites.setTimeout(() => {
            fetchData();
        }, 500);
    }, []);

    const referralColumns = useMemo(
        () => [
            {
                Header: "Username",
                accessor: "username",
            },
            {
                Header: "Joined",
                accessor: "date",
            },
        ],
        []
    );

    const userActivityColumns = useMemo(
        () => [
            {
                Header: "Title",
                accessor: "title",
                Cell: ({ value }) => (
                    <span className={value === "DEDUCT" ? "arrow-down" : "arrow-up"}>
                        {value === "DEDUCT" ? "↓" : "↑"} {value}
                    </span>
                ),
            },
            {
                Header: "Date",
                accessor: "date",
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: ({ value }) => (
                    <span className={value < 0 ? "amount-negative" : "amount-positive"}>
                        {value < 0 ? `-${Math.abs(value).toFixed(2)}` : value.toFixed(2)}
                    </span>
                ),
            },
        ],
        []
    );

    const referralData = useMemo(() => referralList, [referralList]);
    const userActivityData = useMemo(() => userActivity, [userActivity]);

    const referralTable = useTable({ columns: referralColumns, data: referralData }, useSortBy);
    const userActivityTable = useTable({ columns: userActivityColumns, data: userActivityData }, useSortBy);

    const newsSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    return (
        <>
            {referral.length === 0 && (
                <GlowingRedText>Please enter your referral code first</GlowingRedText>
            )}
            <GreenWelcomeText>Welcome {firstname} , you user name is  <b>{userName}</b>, your current rank is <b>{rank}</b></GreenWelcomeText>

            <TopBar accountBalance={accountBalance} dailyprofit={dailyprofit} currentCredits={currentCredits} />

            <VaultSection>
                <VaultCard>
                    <div>My Referrals</div>

                    <TableContainer>
                        <table {...referralTable.getTableProps()}>
                            <thead>
                            {referralTable.headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...referralTable.getTableBodyProps()}>
                            {referralTable.rows.map((row) => {
                                referralTable.prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </TableContainer>
                </VaultCard>

                <VaultCard>
                    <div>Recent Activity</div>

                    <TableContainer>
                        <table {...userActivityTable.getTableProps()}>
                            <thead>
                            {userActivityTable.headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...userActivityTable.getTableBodyProps()}>
                            {userActivityTable.rows.map((row) => {
                                userActivityTable.prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </TableContainer>
                </VaultCard>
            </VaultSection>

            <VaultCard style={{ marginTop: 20 }}>
                <div>Referral Growth in the Last 12 Months</div>
                {referralList.length > 0 && (
                    <Line
                        data={referralChartData}
                        options={{
                            scales: {
                                x: {
                                    beginAtZero: true,
                                },
                                y: {
                                    beginAtZero: true,
                                },
                            },
                        }}
                    />
                )}
            </VaultCard>

            <VaultCard style={{ marginTop: 20 }}>
                <div>Latest News</div>
                {loading ? (
                    <p>Loading news...</p>
                ) : (
                    <NewsCarousel {...newsSettings}>
                        {newsData.map((news, index) => (
                            <NewsItem key={index}>
                                <img src={news.image} alt="News" />
                                <NewsDescription>
                                    <NewsTitle>{news.title}</NewsTitle>
                                </NewsDescription>
                            </NewsItem>
                        ))}
                    </NewsCarousel>
                )}
            </VaultCard>
        </>
    );
};

export default Dashboard;
