import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaUpload, FaCheckCircle } from "react-icons/fa"; // Add FaCheckCircle for completed tasks
import RequestEngine from "../../core/RequestEngine"; // Assuming RequestEngine is located here

// Container for the entire mission screen
const MissionContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
`;

// Grid layout for tasks (cards)
const MissionGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
    gap: 20px; /* Space between the cards */
`;

// Card style for each task
const TaskCard = styled.div`
    background-color: #1c1c28;
    border-radius: 10px;
    padding: 20px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
`;

const TaskHeader = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 18px;

    .icon {
        color: #33ff99;
        margin-right: 10px;
    }

    .completed {
        color: #21ad58; /* Green color for completed tasks */
        margin-left: 10px;
    }
`;

// Proof upload section with image preview
const ProofSection = styled.div`
    background-color: #3b3d3b;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    overflow: hidden;
    margin-top: 20px;
    color: #b0b0b0;
    font-size: 16px;
    height: 180px; /* Set height to 180px */
    display: flex; /* Flexbox to center content */
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        border-radius: 10px;
    }
`;

// Button for uploading proof
const UploadButton = styled.button`
    background-color: #21ad58;
    color: white;
    font-size: 16px;
    padding: 10px;
    width: 100%;
    margin-top: 15px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: #33ff99;
    }

    .icon {
        margin-right: 8px;
    }
`;

const MissionScreen = () => {
    const [proofImages, setProofImages] = useState({}); // State to hold uploaded images
    const [tasks, setTasks] = useState([]); // State to hold the fetched tasks
    const [completedTasks, setCompletedTasks] = useState({}); // State to track completed tasks
    const [loading, setLoading] = useState(true); // State to handle loading status

    // Function to fetch tasks from the API
    const fetchTasks = async () => {
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem("user/missions");
            if (response && response.status === 200 && response.data) {
                setTasks(response.data.data); // Store the fetched tasks
            } else {
                console.error("Failed to fetch tasks:", response.data.message);
            }
        } catch (error) {
            console.error("An error occurred while fetching tasks:", error);
        } finally {
            setLoading(false); // Stop the loading spinner
        }
    };

    // Fetch tasks when component mounts
    useEffect(() => {
        fetchTasks();
    }, []);

    // Handle image selection for proof upload
    const handleImageUpload = (event, taskTitle) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProofImages((prev) => ({ ...prev, [taskTitle]: reader.result }));
                setCompletedTasks((prev) => ({ ...prev, [taskTitle]: true })); // Mark task as completed
            };
            reader.readAsDataURL(file);
        }
    };

    if (loading) {
        return (
            <MissionContainer>
                <h2>Loading Tasks...</h2>
            </MissionContainer>
        );
    }

    return (
        <MissionContainer>
            <h2>Your Tasks</h2>
            <MissionGrid>
                {tasks.map((task, index) => (
                    <TaskCard key={index}>
                        <TaskHeader>
                            <div className="icon">✔️</div>
                            <div>{task.title}</div>
                            {completedTasks[task.title] && (
                                <FaCheckCircle className="completed" />
                            )}
                        </TaskHeader>
                        <ProofSection>
                            {proofImages[task.title] ? (
                                <img
                                    src={proofImages[task.title]}
                                    alt={`Proof for ${task.title}`}
                                />
                            ) : (
                                <div>No proof uploaded yet</div>
                            )}
                        </ProofSection>
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            id={`upload-${task.title}`}
                            onChange={(e) => handleImageUpload(e, task.title)}
                        />
                        <UploadButton
                            onClick={() =>
                                document
                                    .getElementById(`upload-${task.title}`)
                                    .click()
                            }
                        >
                            <FaUpload className="icon" />
                            Upload Proof
                        </UploadButton>
                    </TaskCard>
                ))}
            </MissionGrid>
        </MissionContainer>
    );
};

export default MissionScreen;
