import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation

// Footer container
const FooterContainer = styled.footer`
    background-color: #000000;
    color: white;
    text-align: center;
    padding: 20px;
    margin-top: 40px;
    position: relative;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #33ff99; /* Green top border */
    box-shadow: 0 0 15px #33ff99; /* Glowing effect */
`;

// Text inside the footer
const FooterText = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
`;

// Styled link for the disclaimer and privacy policy
const FooterLink = styled(Link)`
    color: #33ff99;
    text-decoration: none;
    font-weight: bold;
    margin: 0 10px;

    &:hover {
        text-decoration: underline;
    }
`;

// Footer Links container
const FooterLinks = styled.div`
    margin-top: 10px;
`;

const Footer = () => {
    return (
        <FooterContainer>
            <FooterText>&copy; 2024 Multyncome</FooterText>
            <FooterLinks>
                <FooterLink to="/disclaimer">Disclaimer</FooterLink>|
                <FooterLink to="/privacy">Privacy Policy</FooterLink>
            </FooterLinks>
        </FooterContainer>
    );
};

export default Footer;
