import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, useNavigate } from "react-router-dom";
import { Utilites } from "../../core/Utilites";
import RequestEngine from "../../core/RequestEngine";
import { Memory } from "../../core/Memory";
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // Import eye icons
import logo from "../../assets/images/logo.svg";


// Glowing Effect Animation
const glowingEffect = keyframes`
    0% {
        text-shadow: 0 0 5px #33ff99, 0 0 10px #33ff99, 0 0 20px #33ff99, 0 0 30px #757575;
    }
    50% {
        text-shadow: 0 0 10px #33ff99, 0 0 20px #33ff99, 0 0 30px #33ff99, 0 0 40px #33ff99;
    }
    100% {
        text-shadow: 0 0 5px #33ff99, 0 0 10px #33ff99, 0 0 20px #33ff99, 0 0 30px #33ff99;
    }
`;

// Container for the entire login screen
const LoginContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: #fff;

    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }
`;

// Left section with the logo and background
const LeftSection = styled.div`
    flex: 1;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 20px;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

// Logo container
const Logo = styled.div`
    font-size: 48px;
    font-weight: bold;
    color: white;
    text-align: center;
    span {
        display: block;
        margin-top: 15px;
    }

    @media (max-width: 768px) {
        font-size: 36px;
    }
`;

// Glowing Slogan
const Slogan = styled.div`
    font-size: 28px;
    color: #000000;
    text-align: center;
    animation: ${glowingEffect} 1.5s ease-in-out infinite;

    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

// Glowing line separator
const Separator = styled.div`
    width: 1px;
    background-color: #33ff99;
    box-shadow: 0 0 20px rgba(51, 255, 153, 0.8);
    transition: box-shadow 0.5s ease;

    @media (max-width: 768px) {
        display: none; /* Hide the separator on mobile */
    }
`;

// Right section with the login form
const RightSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: #000000;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

// Login form container
const LoginForm = styled.div`
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 20px;
        max-width: 100%;
    }
`;

const Title = styled.h2`
    margin-bottom: 30px;
    color: #000000;
`;

const InputField = styled.input`
    width: 92%;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid ${({ error }) => (error ? 'red' : '#ddd')};
    border-radius: 5px;
    font-size: 16px;
    outline: none;

    &:focus {
        border-color: ${({ error }) => (error ? 'red' : '#33ff99')};
    }
`;

// Wrapper for input field with eye toggle
const PasswordWrapper = styled.div`
    position: relative;
    width: 100%;
`;

// Eye icon button
const ToggleEyeButton = styled.button`
    position: absolute;
    right: 10px;
    top: 12px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: ${({ error }) => (error ? 'red' : '#000000')};

    &:focus {
        outline: none;
    }
`;

const ErrorMessage = styled.div`
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
`;

const SignInButton = styled.button`
    width: 100%;
    background-color: #000000;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #45a049;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

const ButtonLoader = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const FooterText = styled.p`
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    color: #000000;

    a {
        color: #4caf50;
        text-decoration: none;
        margin-left: 5px;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const SecretCodeModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SecretCodeModalContent = styled.div`
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
`;

const SecretCodeInput = styled.input`
    width: 92%;
    padding: 15px;
    font-size: 16px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
`;

const SubmitSecretCodeButton = styled.button`
    background-color: #21AD58;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;

    &:hover {
        background-color: #45a049;
    }
`;

const LoadingSpinner = styled.div`
    margin: 20px auto;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 30px;
    height: 30px;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

// Modal for OTP verification
const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContent = styled.div`
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
`;

const OtpInput = styled.input`
    width:92%;
    padding: 15px;
    font-size: 16px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
`;

const VerifyButton = styled.button`
    background-color: #21AD58;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;

    &:hover {
        background-color: #45a049;
    }
`;

const LoginScreen = () => {
    const [emailOrUsername, setEmailOrUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSecretCodeModal, setShowSecretCodeModal] = useState(false); // To toggle Sec
    const [secretCode, setSecretCode] = useState(''); // State for secret code

    const [mfaEnabled, setMfaEnabled] = useState(false); // To handle MFA check
    const [showOtpModal, setShowOtpModal] = useState(false); // To toggle OTP modal
    const [error, setError] = useState({ emailOrUsername: false, password: false });
    const [errorMessage, setErrorMessage] = useState({ emailOrUsername: '', password: '' });
    const navigate = useNavigate();

    useEffect(() => {
        if (emailOrUsername) {
            setError((prev) => ({ ...prev, emailOrUsername: false }));
            setErrorMessage((prev) => ({ ...prev, emailOrUsername: '' }));
        }
        if (password) {
            setError((prev) => ({ ...prev, password: false }));
            setErrorMessage((prev) => ({ ...prev, password: '' }));
        }
    }, [emailOrUsername, password]);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword); // Toggle password visibility
    };
    const submitSecretCode = async () => {
        if (!secretCode) {
            Utilites.showErrorMessage("Please enter the secret code");
            return;
        }
        submitForm();
    };

    const submitForm = async () => {
        let isValid = true;

        setError({ emailOrUsername: false, password: false });
        setErrorMessage({ emailOrUsername: '', password: '' });

        if (!emailOrUsername) {
            setError((prev) => ({ ...prev, emailOrUsername: true }));
            setErrorMessage((prev) => ({ ...prev, emailOrUsername: 'Please enter your email or username.' }));
            isValid = false;
        }

        if (!password) {
            setError((prev) => ({ ...prev, password: true }));
            setErrorMessage((prev) => ({ ...prev, password: 'Please enter your password.' }));
            isValid = false;
        }

        if (!isValid) return;

        setLoading(true);

        try {
            let engine = new RequestEngine();
            const data = { email: emailOrUsername, password,secret_code: secretCode };
            const response = await engine.postItem("user/login", data);
            setLoading(false);

            if (response && response.status === 200 && response.data.success) {
                const token = response.data.data.token;
                const userid = response.data.data.id;
                const userinfo = response.data.data.user;
                const require_code = response.data.data.require_code;
                const needpasswordchanged = response.data.data.user.needpasswordchanged;
                if(needpasswordchanged || needpasswordchanged===null ){
                    Utilites.showErrorMessage("You must reset your password!")
                    navigate("/forgot");
                    return;
                }
                if(require_code){
                    // ask the user to enter a sepcial code and
                    setShowSecretCodeModal(true);
                }else {
                    Memory.setItem('token', token);
                    Memory.setItem('userinfo', JSON.stringify(userinfo));
                    Memory.setItem('userid', userid);
                    if (userinfo.mfa) {
                        setMfaEnabled(true);
                        setShowOtpModal(true);
                    } else {
                        Memory.setItem('isloggedin', true);
                        navigate('/dashboard');
                    }

                }

            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        } catch (error) {
            setLoading(false);
            Utilites.showErrorMessage("An error occurred. Please try again.");
        }
    };

    const verifyOtp = async () => {
        if (!otp) {
            Utilites.showErrorMessage("Please enter OTP");
            return;
        }

        setLoading(true);

        try {
            let engine = new RequestEngine();
            const data = { otp };
            const response = await engine.postItem("user/verifymfa", data);
            setLoading(false);
            if (response && response.status === 200 && response.data.success) {
                Memory.setItem('isloggedin', true);
                setShowOtpModal(false);
                navigate('/dashboard');
            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        } catch (error) {
            setLoading(false);
            Utilites.showErrorMessage("Failed to verify OTP. Please try again.");
        }
    };

    return (
        <LoginContainer>
            {/* Left Section */}
            <LeftSection>
                <Link to={"/"}>
                    <Logo>
                        <img
                            src={logo}
                            alt="Logo"
                            width={300}
                        />
                        <Slogan>Money Machine</Slogan>
                    </Logo>
                </Link>


            </LeftSection>

            {/* Glowing Separator */}
            <Separator />

            {/* Right Section */}
            <RightSection>
                <LoginForm>
                    <Title>Welcome Back!</Title>
                    <p>Login to get started</p>

                    <InputField
                        type="text"
                        placeholder="Enter your email or username"
                        value={emailOrUsername}
                        error={error.emailOrUsername}
                        onChange={(e) => setEmailOrUsername(e.target.value)}
                    />
                    {error.emailOrUsername && <ErrorMessage>{errorMessage.emailOrUsername}</ErrorMessage>}

                    <PasswordWrapper>
                        <InputField
                            type={showPassword ? 'text' : 'password'} // Toggle input type
                            placeholder="Enter your password"
                            value={password}
                            error={error.password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <ToggleEyeButton onClick={toggleShowPassword} error={error.password}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Show eye or eye slash icon */}
                        </ToggleEyeButton>
                    </PasswordWrapper>
                    {error.password && <ErrorMessage>{errorMessage.password}</ErrorMessage>}

                        <SignInButton onClick={submitForm} disabled={loading}>
                            {loading ? <ButtonLoader /> : 'Sign In'}
                        </SignInButton>

                    <FooterText>
                        Don’t have an account? <Link to="/signup">Signup</Link>
                    </FooterText>
                    <FooterText>
                        <Link to="/forgot">Forgot Password</Link>
                    </FooterText>
                </LoginForm>
            </RightSection>

            {/* MFA OTP Modal */}
            {showOtpModal && (
                <ModalOverlay>
                    <ModalContent>
                        <h3>Please Enter OTP</h3>
                        <OtpInput
                            type="text"
                            value={otp}
                            placeholder="Enter OTP"
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        <VerifyButton onClick={verifyOtp}>Verify</VerifyButton>
                    </ModalContent>
                </ModalOverlay>
            )}
            {showSecretCodeModal && (
                <SecretCodeModalOverlay>
                    <SecretCodeModalContent>
                        <h3>Please Enter Secret Code</h3>
                        <SecretCodeInput
                            type="text"
                            value={secretCode}
                            placeholder="Enter Secret Code"
                            onChange={(e) => setSecretCode(e.target.value)}
                        />
                        <SubmitSecretCodeButton onClick={submitSecretCode}>Submit</SubmitSecretCodeButton>
                    </SecretCodeModalContent>
                </SecretCodeModalOverlay>
            )}

        </LoginContainer>
    );
};

export default LoginScreen;
