import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import RequestEngine from "../../core/RequestEngine";
import { Memory } from "../../core/Memory";
import {Utilites} from "../../core/Utilites"; // Assuming RequestEngine is located here

// Glowing effect for the referral error message
const glowingEffect = keyframes`
    0% {
        text-shadow: 0 0 5px red, 0 0 10px red, 0 0 15px red, 0 0 20px red;
    }
    50% {
        text-shadow: 0 0 10px red, 0 0 20px red, 0 0 30px red, 0 0 40px red;
    }
    100% {
        text-shadow: 0 0 5px red, 0 0 10px red, 0 0 15px red, 0 0 20px red;
    }
`;

// Main container for the screen
const InvestmentContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
`;

// Header for the screen
const Header = styled.h2`
    font-size: 20px;
    margin-bottom: 20px;
`;

// Box for each investment option
const InvestmentOption = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 2px solid ${(props) => (props.selected ? "#21ad58" : "#ddd")};
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    background-color: ${(props) => (props.selected ? "#f0fff4" : "transparent")};
    color: ${(props) => (props.selected ? "#21ad58" : "white")};

    &:hover {
        border-color: #21ad58;
    }
`;

// Best Option badge
const BestOptionBadge = styled.div`
    background-color: #ff9f1c;
    color: white;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 12px;
    text-transform: uppercase;
`;

// Select Button
const SelectButton = styled.button`
    background-color: ${(props) => (props.disabled ? "#ddd" : "#21ad58")};
    color: ${(props) => (props.disabled ? "#777" : "white")};
    border: none;
    border-radius: 10px;
    padding: 15px;
    font-size: 16px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    width: 100%;
    margin-top: 20px;
`;

// Tutorial container for steps
const TutorialContainer = styled.div`
    margin-top: 10px;
    padding: 10px;
    background-color: #1e1e1e;
    border-radius: 8px;
    color: white;
`;

// Each step item in the tutorial
const StepItem = styled.div`
    margin: 10px 0;
    padding: 10px;
    border-left: 4px solid #21ad58;
`;

// Button for steps
const StepButton = styled.a`
    display: inline-block;
    padding: 5px 10px;
    background-color: #21ad58;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    margin-top: 10px;
    margin-left: 20px;

    &:hover {
        background-color: #33ff99;
    }
`;

const TicketContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px; /* Add some space between the items */
    margin-top: 20px;
    overflow-x: auto; /* To ensure it doesn't overflow if the content exceeds the width */
`;

const TicketCard = styled.div`
  background: black;
  border: 1px solid #3b3d3b;
  border-radius: 10px;
  padding: 20px;
  color: white;
  text-align: center;
  width: 100%; /* Adjust the width to fit all cards on one line */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .range {
    font-size: 18px;
    font-weight: bold;
  }

  .percentage {
    font-size: 24px;
    color: #21ad58;
    margin-top: 10px;
  }
`;

// Glowing red text for missing referral code
const GlowingRedText = styled.div`
  font-size: 16px;
  color: red;
  animation: ${glowingEffect} 1.5s ease-in-out infinite;
  text-align: center;
  margin-bottom: 20px;
`;

const InvestmentScreen = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [expandedOption, setExpandedOption] = useState(null); // For expanded row
    const [investmentOptions, setInvestmentOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [referral, setReferral] = useState("");
    const [ismember, setIsmember] = useState(false);


    // Function to fetch investment options
    const fetchInvestmentOptions = async () => {
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem("user/investment");
            if (response && response.status === 200 && response.data) {
                const options = response.data.data.map((item) => ({
                    id: item.id,
                    title: item.title,
                    customerlink: item.customerlink,
                    memberlink: item.memberlink,
                    price: item.price,
                    bestOption: item.bestOption || false,
                }));
                setInvestmentOptions(options);
                Utilites.setTimeout(
                    ()=>{
                        if(options[0]){
                            setSelectedOption(options[0].id)
                            setExpandedOption(options[0].id)
                        }
                    },500
                )

            } else {
                console.error("Failed to fetch investment options:", response.data.message);
            }
        } catch (error) {
            console.error("An error occurred while fetching investment options:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch investment options when the component mounts
    useEffect(() => {
        fetchInvestmentOptions();
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setReferral(user.referral);
            setIsmember(user.ismember)
        };
        fetchData();
    }, []);

    // Handle option click
    const handleOptionClick = (optionId) => {
        setSelectedOption(optionId);
        setExpandedOption(optionId === expandedOption ? null : optionId); // Toggle the expanded panel
    };

    // Handle "Select" button click - Redirect to the selected option's link
    const handleSelectClick = () => {
        const selectedInvestment = investmentOptions.find((option) => option.id === selectedOption);

        if (selectedInvestment) {
            window.open(selectedInvestment.customerlink, "_blank");
        }
    };

    return (
        <InvestmentContainer>

            {/* Show glowing red text if referral code is missing */}
            {referral.length === 0 ? (
                <GlowingRedText>Please enter your referral code first </GlowingRedText>
            ):<div>
                <TicketContainer>
                    <TicketCard>
                        <div className="range">100$-2500$</div>
                        <div className="percentage">50%</div>
                    </TicketCard>
                    <TicketCard>
                        <div className="range">2500$-10,000$</div>
                        <div className="percentage">45%</div>
                    </TicketCard>
                    <TicketCard>
                        <div className="range">10,000$ & above</div>
                        <div className="percentage">40%</div>
                    </TicketCard>
                </TicketContainer>



                {/* Header */}
                <Header>Choose your broker:</Header>

                {loading ? (
                    <p>Loading investment options...</p>
                ) : (
                    <>
                        {investmentOptions.map((option) => (
                            <InvestmentOption
                                key={option.id}
                                selected={selectedOption === option.id}
                                onClick={() => handleOptionClick(option.id)}
                            >
                                <div>
                                    <h3>{option.title}</h3>
                                    <p>Price: {option.price}</p>
                                </div>
                                {option.bestOption && <BestOptionBadge>Best Option</BestOptionBadge>}

                                {expandedOption === option.id && (
                                    <TutorialContainer>
                                        <StepItem>
                                            Step 1: Register now using this link:{" "}
                                            <StepButton href={"https://vc.cabinet.oneroyal.com/links/go/5949"} target="_blank" rel="noopener noreferrer">
                                                Register
                                            </StepButton>
                                        </StepItem>
                                        <StepItem>
                                            Step 2: Complete your verification (Passport/ID, proof of address, email, and agree to PAMM investor agreement)
                                        </StepItem>
                                        <StepItem>
                                            Step 3: Select your agreement <br />
                                            For Customers:
                                            <ul>
                                                <li>100$ to 2500$ (50% fees)
                                                    <StepButton href="https://vc.cabinet.oneroyal.com/brokeree-pamm/investments/register/440/" target="_blank">
                                                        Press here
                                                    </StepButton>
                                                </li>
                                                <li>2501$ to 10k$ (45% fees)
                                                    <StepButton href="https://vc.cabinet.oneroyal.com/brokeree-pamm/investments/register/441/" target="_blank">
                                                        Press here
                                                    </StepButton></li>
                                                <li>10k$ and above (40% fees)
                                                    <StepButton href="https://vc.cabinet.oneroyal.com/brokeree-pamm/investments/register/442/" target="_blank">
                                                        Press here
                                                    </StepButton></li>
                                            </ul>
                                            {ismember &&<div>
                                                For Members:
                                                <ul>
                                                    <li>Always 35%</li>
                                                    <div>
                                                        If your amount is below 5000$:{" "}
                                                        <StepButton href="https://vc.cabinet.oneroyal.com/brokeree-pamm/investment-link/339/MultyncomeBronze/?pid=79650" target="_blank">
                                                            Press here
                                                        </StepButton>
                                                        <br />
                                                        If your amount is 5000$ and above:{" "}
                                                        <StepButton href="https://vc.cabinet.oneroyal.com/brokeree-pamm/investment-link/338/MultyncomeGold/?pid=79650" target="_blank">
                                                            Press here
                                                        </StepButton>
                                                    </div>
                                                </ul>
                                            </div>}

                                        </StepItem>
                                    </TutorialContainer>
                                )}
                            </InvestmentOption>
                        ))}

                        {investmentOptions.length>1 &&<SelectButton disabled={!selectedOption} onClick={handleSelectClick}>
                            Select
                        </SelectButton>}
                    </>
                )}
            </div>}

        </InvestmentContainer>
    );
};

export default InvestmentScreen;
