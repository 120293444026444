import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {useNavigate, useLocation, useParams, Link} from "react-router-dom";
import RequestEngine from "../../core/RequestEngine";

const LegChildContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
`;

const LegCard = styled.div`
    background-color: #1c1c28;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    cursor: pointer;
`;

const LegHeader = styled.div`
    background-color: #212237;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    border-left: 10px solid red;
`;

const ProgressBar = styled.div`
    background-color: #3b3d3b;
    height: 8px;
    border-radius: 5px;
    margin-top: 10px;
    overflow: hidden;
    position: relative;
`;

const Progress = styled.div`
    background-color: #33ff99;
    height: 100%;
    width: ${(props) => props.width || 0}%;
    transition: width 1s ease-in-out;
`;

const ChildLegRow = styled.div`
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-size: 14px;
`;

// Breadcrumb styling
const BreadcrumbContainer = styled.div`
    margin-bottom: 20px;
    font-size: 14px;
    color: #bbb;
    a {
        color: #33ff99;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const Circle = styled.div`
    width: 15px;
    height: 15px;
    background-color: green;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
`;

const LegChildScreen = () => {
    const { legId } = useParams();
    const [childLegs, setChildLegs] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    const { title, progress, credit, rank } = location.state || {};

    // Function to build a hierarchical structure based on referral data
    const buildHierarchy = (data) => {
        const map = {};
        const roots = [];

        data.forEach((item) => {
            map[item.myreferral] = { ...item, children: [] };
        });

        data.forEach((item) => {
            if (item.referral && map[item.referral]) {
                map[item.referral].children.push(map[item.myreferral]);
            } else {
                roots.push(map[item.myreferral]);
            }
        });

        return roots;
    };

    useEffect(() => {
        const fetchLegChildData = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem(`user/legs/${legId}`);
                if (response && response.status === 200 && response.data) {
                    const users = response.data.data;
                    setChildLegs(buildHierarchy(users));
                } else {
                    console.error("Failed to fetch leg data:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching leg data:", error);
            }
        };

        fetchLegChildData();
    }, [legId]);

    const handleLegClick = (child, childId) => {
        child.leg = title
        navigate(`/child/${childId}`, {
            state: child,
        });
    };

    const renderHierarchy = (nodes) => {
        return nodes.map((node) => (
            <div key={node.id} style={{ marginLeft: "100px" }}>
                <LegCard onClick={() => handleLegClick(node, node.id)}>
                    <ChildLegRow>
                        <Circle />
                        <div>
                            {node.title} / {node.rank}
                            <p>{node.credit} credit</p>
                            {node.ismember ? "IS MEMBER" : "NOT A MEMBER"}
                        </div>
                    </ChildLegRow>
                </LegCard>
                {node.children && renderHierarchy(node.children)}
            </div>
        ));
    };

    return (
        <LegChildContainer>
            <BreadcrumbContainer>
                <Link to="/dashboard">Home</Link> &gt; <Link to="/affiliate">Affiliate</Link> &gt; {title}
            </BreadcrumbContainer>
            <LegHeader>
                <h3>{title}</h3>
                <ProgressBar>
                    <Progress width={parseInt(progress)} />
                </ProgressBar>
                <div>
                    <p>Credit: {credit}</p>
                    <p>User Rank: {rank}</p>
                </div>
            </LegHeader>

            {renderHierarchy(childLegs)}
        </LegChildContainer>
    );
};

export default LegChildScreen;
