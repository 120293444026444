import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import RequestEngine from "../../core/RequestEngine";

// Styled components for the PlanSelector page
const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    gap: 20px;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const PlanCard = styled.div`
    background: #1a1a1a;
    border-radius: 10px;
    padding: 20px;
    color: white;
    width: 280px;
    margin: 20px;
    box-shadow: 0 4px 10px #45a049;
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 768px) {
        width: 100%; /* Make the plan card take full width on small screens */
        margin: 10px 0;
    }
`;

const PlanTitle = styled.h3`
    font-size: 24px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const RecommendedBadge = styled.span`
    background-color: #e4044e;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    display: inline-block;
`;

const Price = styled.p`
    font-size: 28px;
    font-weight: bold;
    margin: 10px 0;

    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

const FeatureList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
`;

const FeatureItem = styled.li`
    margin: 5px 0;
    font-size: 14px;

    &:before {
        content: "✔";
        color: #33ff99;
        margin-right: 5px;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
`;

const SubscribeButton = styled.button`
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #45a049;
    }

    @media (max-width: 768px) {
        font-size: 14px;
        padding: 8px 16px;
    }
`;

const PlanSelector = () => {
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPlans = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/plan");
                if (response && response.status === 200 && response.data) {
                    setPlans(response.data.data);
                } else {
                    console.error("Failed to fetch plans:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching plans:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPlans();
    }, []);

    if (loading) {
        return <Container>Loading plans...</Container>;
    }

    return (
        <Container>
            {plans.map((plan) => (
                <PlanCard key={plan.id}>
                    <PlanTitle>{plan.title}</PlanTitle>
                    {plan.featured === "yes" && <RecommendedBadge>Recommended</RecommendedBadge>}
                    <Price>{plan.price}</Price>
                    <FeatureList>
                        {plan.description.split("\n").map((feature, idx) => (
                            <FeatureItem key={idx}>{feature}</FeatureItem>
                        ))}
                    </FeatureList>
                    <SubscribeButton onClick={() => navigate(`/payment/${plan.id}`)}>
                        SUBSCRIBE NOW
                    </SubscribeButton>
                </PlanCard>
            ))}
        </Container>
    );
};

export default PlanSelector;
