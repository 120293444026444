import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites";
import UserSideMenu from "./UserSideMenu";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);
// Main container for the Affiliate screen
const COLORS = ["#33ff99", "#21ad58", "#0b0b0b"];
// Card style for each leg section
const LegCard = styled.div`
    background-color: #1c1c28;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover {
        background-color: #333;
    }
`;

// Header section for the top affiliate info
const AffiliateHeader = styled.div`
    background-color: #212237;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    border-left: 10px solid red;
`;

const ProgressBar = styled.div`
    background-color: #3b3d3b;
    height: 8px;
    border-radius: 5px;
    margin-top: 10px;
    overflow: hidden;
    position: relative;
`;

const Progress = styled.div`
    background-color: #33ff99;
    height: 100%;
    width: ${(props) => props.width || 0}%;
    transition: width 1s ease-in-out;
`;

// Glowing animation for the "CLAIM RANK" button
const glowing = keyframes`
    0% {
        box-shadow: 0 0 5px #33ff99, 0 0 10px #33ff99, 0 0 20px #33ff99;
    }
    50% {
        box-shadow: 0 0 20px #33ff99, 0 0 30px #33ff99, 0 0 40px #33ff99;
    }
    100% {
        box-shadow: 0 0 5px #33ff99, 0 0 10px #33ff99, 0 0 20px #33ff99;
    }
`;

const ClaimButton = styled.button`
    background-color: #21ad58;
    color: white;
    font-size: 18px;
    padding: 15px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 30px;
    width: 100%;
    animation: ${glowing} 1.5s ease-in-out infinite;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #33ff99;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

const ButtonLoader = styled.div`
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;


// Dropdown styling
// Main container for the Affiliate screen
const AffiliateContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
`;
const ChartContainer = styled.div`
    width: 100%;
    height: 300px;
    background-color: #1c1c28;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    
`;

// Metrics Section styling
const MetricsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    background-color: #1c1c28;
    border-radius: 10px;
    padding: 20px;
    margin: auto;
`;

const MetricBox = styled.div`
    background-color: #2b2b3d;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 10px;
`;

const MetricLabel = styled.div`
    font-size: 14px;
    color: #bbb;
`;

const MetricValue = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: ${(props) => (props.isAlert ? "#ff6666" : "#33ff99")}; /* Red if alert, green otherwise */
    display: flex;
    align-items: center;
`;

// Dropdown styling
const Dropdown = styled.select`
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    background-color: #1c1c28;
    color: white;
    border: 1px solid #333;
    border-radius: 5px;
`;

// Partners button styling
const PartnersButton = styled.button`
    background-color: #21ad58;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: auto;
    height: 45px;

    &:hover {
        background-color: #33ff99;
        color: #0b0b0b;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
`;


const AffiliateScreen = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [progressLeg1, setProgressLeg1] = useState(0);
    const [progressLeg2, setProgressLeg2] = useState(0);
    const [progressLegC, setProgressLegC] = useState(0);

    const [creditLeg1, setCreditLeg1] = useState(0);
    const [creditLeg2, setCreditLeg2] = useState(0);
    const [creditLegC, setCreditLegC] = useState(0);

    const [rankLeg1, setRankLeg1] = useState("");
    const [rankLeg2, setRankLeg2] = useState("");
    const [rankLegC, setRankLegC] = useState("");

    const [topuser1, setTopuser1] = useState("");
    const [topuser2, setTopuser2] = useState("");
    const [topuserC, setTopuserC] = useState("");

    const [legs, setLegs] = useState([]);
    const [totalCredits, setTotalCredits] = useState(0);
    const [userRank, setUserRank] = useState("Unranked");
    const [nextrank, setNextrank] = useState({});
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState("monthly"); // Default filter is "monthly"
    const navigate = useNavigate(); // Initialize the navigate function

    const [selectedMonth, setSelectedMonth] = useState("November 2024");
    const [selectedRank, setSelectedRank] = useState("");
    const [rankList, setRankList] = useState([]);
    const [currentrank, setCurrentrank] = useState({});
    const [nextrankneeded, setNextrankneeded] = useState({});
    const [canclaim, setCanclaim] = useState(false);



    const [dc, setDC] = useState(0);

    const openUserSideMenu = (user) => {
        setSelectedUser(user);
    };

    const closeUserSideMenu = () => {
        setSelectedUser(null);
    };




    // Months dropdown data
    const months = [
        "November 2024","October 2024", "September 2024", "August 2024",
        // Add more as required or generate dynamically
    ];

    // Function to fetch legs progress data from API
    const fetchLegData = async () => {
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem(`user/legs?filter=${filter}&month=${selectedMonth}&rank=${selectedRank}`);
            if (response && response.status === 200 && response.data) {
                const legsData = response.data.data;
                setRankList(legsData.ranklist)
                setCurrentrank(legsData.currentrank)
               setNextrankneeded(legsData.nextrank);
                setDC(legsData.currentrank?.dc || 0)



                setCanclaim(legsData.canclaim)
                // Update leg progress and credits dynamically
                let progress1 = parseFloat(legsData.legs[0].progress) || 100;
                let progress2 = parseFloat(legsData.legs[1].progress) || 100;
                let progressc = parseFloat(legsData.legs[2].progress) || 100;

                if(legsData.legs[0].progress.toString()=="0"){
                    progress1 = 0;
                }
                if(legsData.legs[1].progress.toString()=="0"){
                    progress2 = 0;
                }
                if(legsData.legs[2].progress.toString()=="0"){
                    progressc = 0;
                }

                if(progress1==0 && progress2==0 ){
                    progressc = 0;
                }



                setProgressLeg1(progress1);
                setProgressLeg2(progress2 );
                setProgressLegC(progressc );

                setCreditLeg1(legsData.legs[0].credit);
                setCreditLeg2(legsData.legs[1].credit);
                setCreditLegC(legsData.legs[2].credit);

                setRankLeg1(legsData.legs[0].rank);
                setRankLeg2(legsData.legs[1].rank);
                setRankLegC(legsData.legs[2].rank);

                setTopuser1(legsData.legs[0].topuser);
                setTopuser2(legsData.legs[1].topuser);
                setTopuserC(legsData.legs[2].topuser);
                setLegs(legsData.legs);

                setTotalCredits(legsData.mainlegs.credit);
                setUserRank(legsData.mainlegs.rank);


            } else {
                console.error("Failed to fetch legs data:", response.data.message);
            }
        } catch (error) {
            console.error("An error occurred while fetching legs data:", error);
        }
    };



    // Fetch legs data when component mounts or filter changes
    useEffect(() => {
        fetchLegData();
    }, [filter, selectedMonth, selectedRank]);

    const pieData = {
        labels: ["Leg 1", "Leg 2", "Leg C"],
        datasets: [
            {
                label: "Leg Progress",
                data: [progressLeg1, progressLeg2, progressLegC],
                backgroundColor: COLORS,
                hoverOffset: 4,
            },
        ],
    };

    const pieOptions = {
        plugins: {
            legend: {
                display: true,
                position: "right",
            },
        },
        maintainAspectRatio: true,
    };
    // Function to handle click on a leg and navigate to LegChildScreen with the leg ID
    const handleLegClick = (leg) => {
        openUserSideMenu(leg.topuser)
    };

    // Function to handle "CLAIM RANK" button click
    const handleClaimRank = async () => {
        setLoading(true);
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem("claim/" + nextrank?.id);
            if (response && response.status === 200 && response.data.success) {
                Utilites.showSucessMessage("Rank claimed successfully!");
                fetchLegData();
            } else {
                Utilites.showErrorMessage("Failed to claim rank: " + response.data.message);
            }
        } catch (error) {
            console.error("An error occurred while claiming rank:", error);
            Utilites.showErrorMessage("An error occurred while claiming rank.");
        }finally {
            setLoading(false);
        }
    };


    return (
        <AffiliateContainer>
            <FilterContainer>
                <Dropdown value={selectedRank} onChange={(e) => setSelectedRank(e.target.value)}>
                    <option value="">Current Progress</option>
                    {rankList.map((rank, index) => (
                        <option key={index} value={rank._id}>
                            {rank.title}
                        </option>
                    ))}
                </Dropdown>
                {filter == "monthly" && (
                    <Dropdown value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                        <option value="">Select Month</option>
                        {months.map((month, index) => (
                            <option key={index} value={month}>
                                {month}
                            </option>
                        ))}
                    </Dropdown>
                )}
                <Dropdown value={filter} onChange={(e) => {
                    setSelectedMonth("");
                    setFilter(e.target.value);
                }}>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                </Dropdown>

                <PartnersButton onClick={() => {
                    navigate("/partners")
                }}>Partners
                </PartnersButton>
            </FilterContainer>

            <AffiliateHeader>
                <h3>Total: {totalCredits} credits</h3>
                <p>User Rank: {userRank}</p>
                <p>Next Rank: {nextrankneeded?.title} </p>
            </AffiliateHeader>

            <LegCard onClick={() => handleLegClick({ topuser: topuser1 })}>
                <h4>Leg 1</h4>
                <ProgressBar><Progress width={progressLeg1} /></ProgressBar>
                <div>
                    <p>{progressLeg1.toFixed(0)}% &nbsp;&nbsp;{creditLeg1} credits</p>
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                        <div>Needs: {rankLeg1}</div>
                        <div>User: {topuser1?.username}</div>
                    </div>
                </div>
            </LegCard>

            <LegCard onClick={() => handleLegClick({ topuser: topuser2 })}>
                <h4>Leg 2</h4>
                <ProgressBar><Progress width={progressLeg2} /></ProgressBar>
                <div>
                    <p>{progressLeg2.toFixed(0)}% &nbsp;&nbsp;{creditLeg2} credits</p>
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                        <div>Needs: {rankLeg2}</div>
                        <div>User: {topuser2?.username}</div>
                    </div>
                </div>
            </LegCard>

            <LegCard onClick={() => handleLegClick({ topuser: topuserC })}>
                <h4>Leg C</h4>
                <ProgressBar><Progress width={progressLegC} /></ProgressBar>
                <div>
                    <p>{progressLegC.toFixed(0)}% &nbsp;&nbsp;{creditLegC} credits</p>
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                        <div>Needs: {rankLegC} </div>
                        <div>User: {topuserC?.username}</div>
                    </div>
                </div>
            </LegCard>

            {progressLeg1 === 100 && progressLeg2 === 100 && progressLegC === 100 && canclaim && (
                <ClaimButton onClick={handleClaimRank} disabled={loading}>
                    {loading ? <ButtonLoader /> : "CLAIM RANK"}
                </ClaimButton>
            )}

            <MetricsContainer>

                    <div style={{display: 'flex',margin:"auto"}}>
                        <MetricBox>
                            <MetricLabel>DC</MetricLabel>
                            <MetricValue isAlert={dc < 1000}>0/{dc}</MetricValue>
                        </MetricBox>
                        <MetricBox>
                            <MetricLabel>Total Credits</MetricLabel>
                            <MetricValue>{totalCredits}</MetricValue>
                        </MetricBox>
                    </div>


                <ChartContainer>
                    <h4>Legs Progress Overview</h4>
                    <Pie data={pieData} options={pieOptions} />
                </ChartContainer>
            </MetricsContainer>



            {selectedUser && (
                <UserSideMenu user={selectedUser} onClose={closeUserSideMenu} />
            )}
        </AffiliateContainer>
    );
};

export default AffiliateScreen;
