import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useParams } from 'react-router-dom';
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites"; // Assuming RequestEngine is located here

// Container for the Course Details
const CourseDetailsContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
`;

// Course Image
const CourseImage = styled.img`
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
`;

// Title and Description Section
const CourseTitle = styled.h2`
    font-size: 24px;
    color: #33ff99;
`;

const CourseDescription = styled.p`
    font-size: 16px;
    color: #bbb;
    line-height: 1.5;
`;

const Rating = styled.div`
    margin: 10px 0;
    font-size: 18px;
    color: gold;
`;

// Button Glow Animation
const glow = keyframes`
    0% {
        box-shadow: 0 0 10px #21ad58, 0 0 20px #21ad58, 0 0 30px #21ad58;
    }
    100% {
        box-shadow: 0 0 20px #33ff99, 0 0 30px #33ff99, 0 0 40px #33ff99;
    }
`;

// Enroll Now Button
const EnrollButton = styled.button`
    background-color: #21ad58;
    color: white;
    padding: 15px 30px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    animation: ${glow} 1.5s infinite alternate;

    &:hover {
        background-color: #33ff99;
    }
`;

const CourseDetails = () => {
    const { id } = useParams(); // Get course id from the URL
    const [course, setCourse] = useState(null); // State to store course details
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const fetchCourseDetails = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem(`user/course/details/${id}`);
                if (response && response.status === 200 && response.data) {
                    setCourse(response.data.data); // Store the fetched course details
                } else {
                    console.error("Failed to fetch course details:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching course details:", error);
            } finally {
                setLoading(false); // Stop the loading spinner
            }
        };

        fetchCourseDetails(); // Fetch course details when component mounts
    }, [id]);

    if (loading) {
        return (
            <CourseDetailsContainer>
                <h2>Loading Course Details...</h2>
            </CourseDetailsContainer>
        );
    }

    if (!course) {
        return (
            <CourseDetailsContainer>
                <h2>Course not found</h2>
            </CourseDetailsContainer>
        );
    }

    return (
        <CourseDetailsContainer>
            {/* Course Image */}
            <CourseImage
                src={course.fullpicture} // Use course image from API response
                alt={course.title}
            />

            {/* Course Title and Description */}
            <CourseTitle>{course.title}</CourseTitle>
            <Rating>★★★★★</Rating>
            <CourseDescription>{course.description}</CourseDescription>

            {/* Enroll Now Button */}
            <EnrollButton onClick={() => {
                Utilites.showSucessMessage("You can check it in the app soon!"); // Show message when clicked
            }}>
                Enroll Now
            </EnrollButton>
        </CourseDetailsContainer>
    );
};

export default CourseDetails;
