import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaWallet, FaPaperPlane, FaFileInvoiceDollar, FaEye, FaEyeSlash } from "react-icons/fa";
import { Memory } from "../../core/Memory";
import { Utilites } from "../../core/Utilites";
import RequestEngine from "../../core/RequestEngine";
import {useNavigate} from "react-router-dom"; // Assuming the request engine is already set up

// Styled components
const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: #1c1c28;
    padding: 30px;
    border-radius: 10px;
    width: 400px;
    max-width: 100%;
    color: white;
    text-align: center;

    @media (max-width: 768px) {
        width: 90%;
    }
`;

const InputWrapper = styled.div`
    position: relative;
    width: 92%;
    margin-bottom: 15px;
`;

const Input = styled.input`
    width: 95%;
    padding: 15px;
    background-color: #1e1e1e;
    border: 1px solid ${({ error }) => (error ? "red" : "#333")};
    color: #fff;
    border-radius: 8px;
    font-size: 16px;

    &:focus {
        outline: none;
        border-color: ${({ error }) => (error ? "red" : "#33ff99")};
        background-color: #333;
    }
`;

const ToggleButton = styled.span`
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color:#21AD58;
`;

const ErrorMessage = styled.div`
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
`;

const Button = styled.button`
    width: 100%;
    padding: 15px;
    background-color: #21ad58;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    margin-top: 20px;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #33ff99;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

const ButtonLoader = styled.div`
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const CloseButton = styled(Button)`
    background-color: red;

    &:hover {
        background-color: darkred;
    }
`;

const PerformanceSection = styled.div`
    display: flex;
    justify-content: space-between;
    background: black;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #3b3d3b;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const ButtonSection = styled.div`
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
    align-items: center;
`;

const ButtonIcon = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-size: 16px;
    transition: color 0.3s ease;

    .icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #E0F2F1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        font-size: 24px;
        color: black;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    &:hover {
        color: #21AD58;

        .icon {
            background-color: #21AD58;
            color: white;
        }
    }
`;



const MetricSection = styled.div`
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
`;

const Metric = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 18px;

    .value {
        font-size: 24px;
        font-weight: bold;
        color: ${(props) => props.color || "#33FF99"};
    }

    @media (max-width: 768px) {
        margin-bottom: 15px;
    }
`;

const TopBar = ({ accountBalance, dailyprofit, currentCredits }) => {
    const [modalData, setModalData] = useState(null);
    const navigate = useNavigate();
    const [isMfaEnabled, setIsMfaEnabled] = useState(false);
    const [showMfaModal, setShowMfaModal] = useState(false);
    const [mfaValidation, setMfaValidation] = useState(true);
    const [trc, setTrc] = useState("");
    const [otptitle, setOtptitle] = useState("Enter MFA Key");
    const [inputValues, setInputValues] = useState({
        "TRC20 Address": "", // Keep TRC20 Address pre-filled
    });
    const [otp, setOtp] = useState("");
    const [errors, setErrors] = useState({});
    const [transactionData, setTransactionData] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false); // For password toggle
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchMfaStatus = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setTrc(user.trc);
            setInputValues((prevValues) => ({
                ...prevValues,
                "TRC20 Address": user.trc, // Pre-fill TRC20 Address
            }));
            setIsMfaEnabled(user.mfa === true);
        };
        fetchMfaStatus();
    }, []);

    const handleInputChange = (e, field) => {
        let value = e.target.value;
        if (field === "Amount") {
            // Allow only numbers for the Amount field
            value = value.replace(/\D/g, "");
        }

        setInputValues({
            ...inputValues,
            [field]: value,
        });

        if (value) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: false,
            }));
        }
    };

    const handleTopUpClick = () => {
            navigate("/topup");
    };

    const handleSendClick = () => {
        setOtp("")
        setModalData({
            title: "Send",
            fields: ["Username", "Password", "Amount"],
        });
        clearInputsExceptTRC();
    };

    const handleRequestClick = () => {
        setOtp("")
        setModalData({
            title: "withdraw",
            fields: ["Password","Amount","TRC20 Address"],
        });
        clearInputsExceptTRC();
    };

    const clearInputsExceptTRC = () => {
        setInputValues((prevValues) => ({
            "TRC20 Address": prevValues["TRC20 Address"], // Keep TRC20 Address intact
            Password: "",
            Amount: "",
            Username: "",
        }));
    };

    const handleSubmit = async () => {
        const requiredFields = modalData.fields;
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!inputValues[field]) {
                newErrors[field] = true;
            }
        });

        if (modalData.title != "Send" && inputValues["Amount"] && parseFloat(inputValues["Amount"]) < 100) {
            newErrors["Amount"] = true;
            Utilites.showErrorMessage("The amount must be at least 100.");
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        if (isMfaEnabled) {
            setTransactionData({
                type: modalData.title.toLowerCase(),
                ...inputValues,
            });
            setModalData(null);
            setShowMfaModal(true);
            setOtptitle("Enter MFA Key")
        } else {
            const engine = new RequestEngine();
            engine.getItem("user/generateotp");
            Utilites.showSucessMessage("OTP sent to your email!");
            setTransactionData({
                type: modalData.title.toLowerCase(),
                ...inputValues,
            });
            setModalData(null);
            setShowMfaModal(true);
            setOtptitle("Enter OTP Key")
        }
    };

    const validateOtp = async () => {
        setLoading(true);
        try {
            const data = {
                otp: otp,
                method: "mfa",
                type: transactionData.type,
                username: transactionData.Username || "",
                amount: transactionData.Amount,
                password: transactionData.Password,
                trc: transactionData["TRC20 Address"],
            };

            const engine = new RequestEngine();
            const response = await engine.postItem("user/validateotp", data);

            if (response && response.status === 200 && response.data.success) {
                Utilites.showSucessMessage("Done!");
                setShowMfaModal(false);
            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        } catch (error) {
            Utilites.showErrorMessage("An error occurred during OTP validation.");
        }finally {
            setLoading(false);
        }
    };

    const handleMfaSubmit = () => {
        if (!otp) {
            Utilites.showErrorMessage("Please enter the OTP.");
            return;
        }
        validateOtp();
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const closeModal = () => {
        setModalData(null);
        setShowMfaModal(false);
    };

    return (
        <div>
            <PerformanceSection>
                <ButtonSection>
                    <ButtonIcon onClick={handleTopUpClick}>
                        <div className="icon">
                            <FaWallet />
                        </div>
                        <div>Top Up</div>
                    </ButtonIcon>
                    <ButtonIcon onClick={handleSendClick}>
                        <div className="icon">
                            <FaPaperPlane />
                        </div>
                        <div>Send</div>
                    </ButtonIcon>
                    <ButtonIcon onClick={handleRequestClick}>
                        <div className="icon">
                            <FaFileInvoiceDollar />
                        </div>
                        <div>Request</div>
                    </ButtonIcon>
                </ButtonSection>



                <MetricSection>
                    <Metric>
                        <div>Today Profit</div>
                        <div className="value">{dailyprofit}$</div>
                    </Metric>
                    <Metric>
                        <div>Coins</div>
                        <div className="value">0</div>
                    </Metric>
                    <Metric color="#33FF99">
                        <div>Balance</div>
                        <div className="value">${accountBalance}</div>
                    </Metric>
                </MetricSection>
            </PerformanceSection>

            {modalData && (
                <ModalOverlay>
                    <ModalContent>
                        <h2>{modalData.title}</h2>
                        {modalData.fields.map((field, index) => (
                            <InputWrapper key={index}>
                                <Input
                                    type={field === "Password" && !passwordVisible ? "password" : "text"}
                                    placeholder={field}
                                    error={errors[field]}
                                    value={inputValues[field] || ""}
                                    onChange={(e) => handleInputChange(e, field)}
                                />
                                {field === "Password" && (
                                    <ToggleButton onClick={togglePasswordVisibility}>
                                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                    </ToggleButton>
                                )}
                                {field!="Amount" && errors[field] && <ErrorMessage>{field} is required</ErrorMessage>}
                                {field=="Amount" && errors[field] && <ErrorMessage>Amount must be minimum 100</ErrorMessage>}
                            </InputWrapper>
                        ))}
                        <Button onClick={handleSubmit}>Submit</Button>
                        <CloseButton onClick={closeModal}>Close</CloseButton>
                    </ModalContent>
                </ModalOverlay>
            )}

            {showMfaModal && (
                <ModalOverlay>
                    <ModalContent>
                        <h2>{otptitle}</h2>
                        <Input
                            type="text"
                            placeholder="MFA Key"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        <Button onClick={handleMfaSubmit} disabled={loading}>
                            {loading ? <ButtonLoader /> : "Verify"}
                        </Button>
                        <CloseButton onClick={closeModal}>Close</CloseButton>
                    </ModalContent>
                </ModalOverlay>
            )}
        </div>
    );
};

export default TopBar;
