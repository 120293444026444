import React from 'react';
import FadeUpTrigger from "../../animation/FadeUpTrigger";
import ButtonDefault from "../button/button-default/ButtonDefault";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import BoxRight from "../box-right/BoxRight";
import {ParallaxImageProps} from "../parallax-image/ParallaxImage";

const heroContent = {
    title: "Let AI Trade for You While You Sleep.",
    src: '/assets/img/AI.webp',
    description: `Imagine a world where you no longer have to stress over the markets, where trading becomes simple, automated, and profitable. With Multyncome, that world is now a reality. Our AI-powered trading solution manages everything for you, so you can focus on enjoying life while we handle the trades.`,
    // to: "portfolio/bastian-bux",
    // textButton: "LEARN MORE",
    // icon : faAngleRight
};

function HeroSectionTow({...restProps}: ParallaxImageProps) {
    return (
        <BoxRight  {...restProps} src={heroContent.src}>
            <FadeUpTrigger>
                <h2 className="pre-line">{heroContent.title}</h2>

                <p className="mt-30 m-w750 dsn-auto">{heroContent.description}</p>
                {/* <ButtonDefault className="mt-30" icon={heroContent.icon} text={heroContent.textButton}
                               to={heroContent.to}/> */}
            </FadeUpTrigger>
        </BoxRight>
    );
}

export default HeroSectionTow;
