import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons for password visibility toggle
import { Memory } from "../../core/Memory";
import { toast } from "react-toastify";
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites";
import { QRCodeSVG } from 'qrcode.react';
import PhoneInput from 'react-phone-input-2'; // Import phone input library
import 'react-phone-input-2/lib/style.css'; // Import phone input styles
import Constants from "../../core/Constants";

// Styled components for the Edit Profile Screen
const Container = styled.div`
    padding: 20px;
    color: white;
    background-color: #000000;
    min-height: 100vh;
`;

const SectionTitle = styled.h2`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #33ff99;
`;

const Label = styled.label`
    font-size: 14px;
    color: #fff;
    margin-bottom: 5px;
    display: block;
`;

const InputField = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #333;
    background-color: #1c1c28;
    border-radius: 5px;
    font-size: 16px;
    color: white;
    position: relative;
`;

const InputWrapper = styled.div`
    position: relative;
`;

const EyeIcon = styled.div`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #33ff99;
`;

const Dropdown = styled.select`
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #333;
    background-color: #1c1c28;
    border-radius: 5px;
    color: white;
`;

const Button = styled.button`
    background-color: #21ad58;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #33ff99;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

const ButtonLoader = styled.div`
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;
const Section = styled.div`
    margin-bottom: 30px;
    position: relative;
`;

const MFAConfigureLink = styled.a`
    color: #33ff99;
    cursor: pointer;
    text-decoration: underline;
    display: block;
    margin-top: 10px;
    font-size: 14px;

    &:hover {
        color: #21ad58;
    }
`;

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const ModalContent = styled.div`
    background-color: #1c1c28;
    padding: 30px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    color: white;
    text-align: center;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    color: #33ff99;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 10px;
`;

const EditProfileScreen = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [trc, setTrc] = useState("");
    const [username, setUsername] = useState("");
    const [referral, setReferral] = useState("");
    const [caneditreferral, setCaneditreferral] = useState(false);
    const [nationalId, setNationalId] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [country, setCountry] = useState("");
    const [isMfaEnabled, setIsMfaEnabled] = useState(false);
    const [countries, setCountries] = useState([]);
    const [obscurePassword, setObscurePassword] = useState(true);
    const [obscureConfirmPassword, setObscureConfirmPassword] = useState(true);
    const [obscureOldPassword, setObscureOldPassword] = useState(true);
    const [phone, setPhone] = useState(""); // Phone number state
    const [loading, setLoading] = useState(false); // Loading state

    const [mfaSecret, setMfaSecret] = useState("");
    const [otpAuthUrl, setOtpAuthUrl] = useState("");
    const [showMFAModal, setShowMFAModal] = useState(false);
    const [showMFAKeyInput, setShowMFAKeyInput] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setEmail(user.email);
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setUsername(user.username);
            setNationalId(user.nationalId);
            setCountry(user.country);
            setReferral(user.referral);
            setCaneditreferral(user.referral.length == 0);
            setTrc(user.trc);
            setIsMfaEnabled(user.mfa === true);
            setCountries(["USA", "Canada", "Germany", "India"]);
            setPhone(user.phone_number); // Set phone number from user data
        };
        fetchData();
    }, []);

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
        setLoading(true); // Start loading

        const data = {
            password,
            oldPassword,
            mfa: isMfaEnabled,
            trc,
            referral,
            phone, // Add phone to the form data
        };

        const engine = new RequestEngine();
        const response = await engine.postItem("user/updateprofile", data);

        if (response && response.status === 200 && response.data.success) {
            const userinfo = response.data.data.user;
            Memory.setItem("userinfo", JSON.stringify(userinfo));
            Utilites.showSucessMessage("Profile Updated!");
        } else {
            Utilites.showErrorMessage(response.data.message);
        }
        setLoading(false);
    };

    const fetchMFAData = async () => {
        const engine = new RequestEngine();
        const response = await engine.getItem("user/enablemfa");
        if (response && response.data) {
            setMfaSecret(response.data.data.base32);
            setOtpAuthUrl(response.data.data.otpauth_url);
            setShowMFAModal(true);
        } else {
            Utilites.showErrorMessage("Failed to load MFA configuration");
        }
    };

    const handleMFAConfigure = () => {
        fetchMFAData();
    };

    const handleNextStep = () => {
        setShowMFAModal(false);
        setShowMFAKeyInput(true);
    };

    const ApplyMFAScreen = () => {
        const [mfaKey, setMfaKey] = useState("");

        const verifyMFAKey = async () => {
            if (!mfaKey) {
                toast.error("Please enter a valid MFA key");
                return;
            }

            const data = { otp: mfaKey };
            const engine = new RequestEngine();
            const response = await engine.postItem("user/verifymfa", data);

            if (response && response.status === 200 && response.data.success) {
                Utilites.showSucessMessage("MFA Enabled!");
                setShowMFAKeyInput(false);
            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        };

        return (
            <Modal>
                <ModalContent>
                    <CloseButton onClick={() => setShowMFAKeyInput(false)}>X</CloseButton>
                    <h3>Apply MFA</h3>
                    <Label>Enter your MFA key</Label>
                    <InputField
                        type="text"
                        value={mfaKey}
                        placeholder="MFA Key"
                        onChange={(e) => setMfaKey(e.target.value)}
                    />
                    <Button onClick={verifyMFAKey}>Verify</Button>
                </ModalContent>
            </Modal>
        );
    };

    return (
        <Container className={"edit"}>
            <SectionTitle>View Only</SectionTitle>
            <Section>
                <Label>Username</Label>
                <InputField type="text" value={username} readOnly placeholder="Username" />

                <Label>First Name</Label>
                <InputField type="text" value={firstName} readOnly placeholder="First Name" />

                <Label>Last Name</Label>
                <InputField type="text" value={lastName} readOnly placeholder="Last Name" />

                <Label>Email</Label>
                <InputField type="email" value={email} readOnly placeholder="Email" />

                <Label>Country</Label>
                <Dropdown value={country} disabled>
                    {Constants.countries.map((country) => (
                        <option key={country} value={country}>
                            {country}
                        </option>
                    ))}
                </Dropdown>

                <Label>National ID</Label>
                <InputField type="text" value={nationalId} readOnly placeholder="National ID" />

                <Label>Referral</Label>
                {!caneditreferral && (
                    <InputField type="text" value={referral} readOnly placeholder="Referral" />
                )}
                {caneditreferral && (
                    <InputField
                        type="text"
                        value={referral}
                        onChange={(e) => setReferral(e.currentTarget.value)}
                        placeholder="Referral"
                    />
                )}

                <Label>Phone Number</Label>
                <PhoneInput
                    country={'lb'}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    containerStyle={{ marginBottom: '10px' }}
                    inputStyle={{ padding: "20px 20px 20px 52px",
                        borderRadius: 5,
                        border: "1px solid #333333",
                        fontSize: 16,
                        width: "100%",
                        background: "#14141C",
                        color: "white"}}

                />

                <Label>TRC20 Address</Label>
                <InputField
                    type="trc"
                    value={trc}
                    onChange={(e) => setTrc(e.currentTarget.value)}
                    placeholder="TRC20 Address"
                />
            </Section>

            <SectionTitle>Change Password</SectionTitle>
            <Section>
                <Label>Old Password</Label>
                <InputWrapper>
                    <InputField
                        type={obscureOldPassword ? "password" : "text"}
                        value={oldPassword}
                        placeholder="Password"
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <EyeIcon onClick={() => setObscureOldPassword(!obscureOldPassword)}>
                        {obscureOldPassword ? <FaEyeSlash /> : <FaEye />}
                    </EyeIcon>
                </InputWrapper>

                <Label>Password</Label>
                <InputWrapper>
                    <InputField
                        type={obscurePassword ? "password" : "text"}
                        value={password}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <EyeIcon onClick={() => setObscurePassword(!obscurePassword)}>
                        {obscurePassword ? <FaEyeSlash /> : <FaEye />}
                    </EyeIcon>
                </InputWrapper>

                <Label>Confirm Password</Label>
                <InputWrapper>
                    <InputField
                        type={obscureConfirmPassword ? "password" : "text"}
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <EyeIcon onClick={() => setObscureConfirmPassword(!obscureConfirmPassword)}>
                        {obscureConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </EyeIcon>
                </InputWrapper>
            </Section>

            <SectionTitle>Multi-Factor Authentication</SectionTitle>
            <Section>
                <label>
                    <input
                        type="checkbox"
                        checked={isMfaEnabled}
                        onChange={() => setIsMfaEnabled(!isMfaEnabled)}
                    />
                    Enable MFA
                </label>
                {isMfaEnabled && (
                    <MFAConfigureLink onClick={handleMFAConfigure}>
                        Configure MFA
                    </MFAConfigureLink>
                )}
            </Section>

            <Button onClick={handleSubmit} disabled={loading}>
                {loading ? <ButtonLoader /> : "Save Changes"}
            </Button>

            {/* MFA Modal */}
            {showMFAModal && (
                <Modal>
                    <ModalContent>
                        <CloseButton onClick={() => setShowMFAModal(false)}>X</CloseButton>
                        <h3>MFA Setup</h3>
                        <p>Scan the QR code using your authenticator app or use the secret key below.</p>
                        <QRCodeSVG value={otpAuthUrl} size={200} />
                        <div style={{ marginTop: "20px" }}>
                            <Label>MFA Secret</Label>
                            <InputField type="text" value={mfaSecret} readOnly />
                            <Button
                                onClick={() => {
                                    navigator.clipboard.writeText(mfaSecret);
                                    Utilites.showSucessMessage("Copied!");
                                }}
                            >
                                Copy MFA Secret
                            </Button>
                        </div>
                        <Button onClick={handleNextStep}>Next</Button>
                    </ModalContent>
                </Modal>
            )}

            {/* MFA Key Input Modal */}
            {showMFAKeyInput && <ApplyMFAScreen />}
        </Container>
    );
};

export default EditProfileScreen;
