import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FaBell, FaBars, FaTimes, FaShareAlt } from "react-icons/fa";
import { Memory } from "../../core/Memory";
import logo from "../../assets/images/logo.svg";
import { Utilites } from "../../core/Utilites";
import RequestEngine from "../../core/RequestEngine";

// Styled Components
const MenuBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #050505;
    color: white;
    position: relative;
    z-index: 1000;
`;

const LeftSection = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;

    span {
        margin-left: 10px;
    }
`;

const MenuItems = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;

    a {
        color: #21ad58;
        text-decoration: none;
        font-size: 16px;
        transition: 0.3s;

        &:hover {
            color: #33ff99;
        }
    }

    .active {
        color: #33ff99;
        border-bottom: 2px solid #33ff99;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const RightSection = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const NotificationIcon = styled.div`
    cursor: pointer;
    font-size: 20px;
    position: relative;

    &:hover {
        color: #33ff99;
    }

    .notification-count {
        position: absolute;
        top: -5px;
        right: -10px;
        background-color: red;
        color: white;
        border-radius: 50%;
        padding: 2px 6px;
        font-size: 12px;
    }
`;

const ShareIcon = styled.div`
    cursor: pointer;
    font-size: 20px;
    position: relative;

    &:hover {
        color: #33ff99;
    }
`;

const NotificationDropdown = styled.div`
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #212237;
    border-radius: 5px;
    width: 250px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    padding: 10px;
    z-index: 10;

    .notification-item {
        display: block;
        color: #fff;
        text-decoration: none;
        padding: 10px;
        font-size: 14px;
        transition: background-color 0.2s;

        &:hover {
            background-color: #333;
            cursor: pointer;
        }
    }

    .no-notification {
        text-align: center;
        color: #ccc;
        font-size: 14px;
    }
`;

const UserProfile = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fff;
    }

    .profile-info {
        font-size: 14px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const SubMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #212237;
    border-radius: 5px;
    width: 150px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    padding: 10px;
    z-index: 10;

    a {
        display: block;
        color: #fff;
        text-decoration: none;
        padding: 10px;
        font-size: 14px;
        transition: background-color 0.2s;

        &:hover {
            background-color: #333;
        }
    }
`;

const HamburgerIcon = styled.div`
    font-size: 24px;
    color: #21ad58;
    display: none;
    cursor: pointer;

    @media (max-width: 768px) {
        display: block;
    }
`;

const MobileMenuDropdown = styled.div`
    display: ${(props) => (props.open ? "block" : "none")};
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: #050505;
    border-radius: 5px;
    width: 200px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    padding: 15px;
    z-index: 1000;

    a {
        display: block;
        color: #21ad58;
        text-decoration: none;
        font-size: 16px;
        padding: 10px 0;

        &:hover {
            color: #33ff99;
        }
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    width: 400px;

    h2 {
        margin-bottom: 15px;
        color: #000;
    }

    p {
        font-size: 16px;
        color: #000;
    }

    button {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #21ad58;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: #33ff99;
        }
    }
`;

export const MainMenu = () => {
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);
    const [username, setUsername] = useState("");
    const [myreferral, setMyreferral] = useState("");
    const [referral, setReferral] = useState("");

    const [ismember, setIsmember] = useState(false);
    const [rank, setRank] = useState(""); // Add rank state
    const [membershipExpiry, setMembershipExpiry] = useState(""); // Add membership expiry state

    const [isNotificationOpen, setNotificationOpen] = useState(false);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false); // Modal visibility state

    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setUsername(user.username);
            setMyreferral(user.myreferral);
            setIsmember(user.ismember);
            setReferral(user.referral);
        };

        const fetchNotifications = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/notification/list");
                if (response && response.status === 200 && response.data) {
                    setNotifications(response.data.data);
                    setNotificationCount(response.data.data.length);
                }
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        };

        fetchData();
        Utilites.setTimeout(
            ()=>{
                fetchNotifications()
            },1500
        )
        Utilites.setTimeout(
            ()=>{
                handleMemberClick(null,false)
            },1000
        )
    }, []);

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };

    const toggleNotificationMenu = () => {
        setNotificationOpen(!isNotificationOpen);
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleShare = () => {
        const shareLink = `https://multyncome.com/signup?ref=${myreferral}`;
        navigator.clipboard.writeText(shareLink);
        Utilites.showSucessMessage("Link Copied! : " + shareLink);
    };

    const handleMemberClick = async (e,showModal=true) => {
        e?.stopPropagation();
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem("user/me");
            if (response && response.status === 200 && response.data.success) {
                const { rank, membershipexpiry } = response.data.data.userinfo;
                Memory.setItem('userinfo', JSON.stringify(response.data.data.userinfo));

                setRank(rank?.title || "Unranked");
                setMembershipExpiry(Utilites.renderDate(membershipexpiry));
                if(showModal){
                    setModalOpen(true); // Open the modal when MEMBER is clicked
                }

            }else{
                Utilites.showErrorMessage("error please try again later")
            }
        } catch (error) {
            console.error("Error fetching membership details:", error);
        }
    };

    const closeModal = () => {
        setModalOpen(false); // Close the modal
    };

    return (
        <MenuBar>
            <LeftSection>
                <Logo>
                    <img
                        src={logo}
                        alt="Logo"
                        width={150}
                    />
                </Logo>
            </LeftSection>

            <MenuItems>
                <NavLink exact to="/dashboard" activeClassName="active">Home</NavLink>
                <NavLink to="/activity" activeClassName="active">Transactions</NavLink>
                {ismember && <NavLink to="/affiliate" activeClassName="active">Affiliate</NavLink>}
                <NavLink to="/rank" activeClassName="active">Rank</NavLink>
                {ismember && <NavLink to="/courses" activeClassName="active">Courses</NavLink>}
                <NavLink to="/investment" activeClassName="active">Investment</NavLink>
                <NavLink to="/calculator" activeClassName="active">Calculator</NavLink>
                {!ismember && <NavLink to="/subscribe" activeClassName="active" style={{color:"red"}}>Subscribe!</NavLink>}
            </MenuItems>

            <RightSection>
                <NotificationIcon onClick={toggleNotificationMenu}>
                    <FaBell />
                    {notificationCount > 0 && (
                        <span className="notification-count">{notificationCount}</span>
                    )}
                    {isNotificationOpen && (
                        <NotificationDropdown>
                            {notifications.length > 0 ? (
                                notifications.map((notification) => (
                                    <div key={notification.id} className="notification-item">
                                        {notification.message}
                                    </div>
                                ))
                            ) : (
                                <div className="no-notification">No notifications</div>
                            )}
                        </NotificationDropdown>
                    )}
                </NotificationIcon>

                {ismember &&<ShareIcon onClick={handleShare}>
                    <FaShareAlt />
                </ShareIcon>}

                <HamburgerIcon onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
                </HamburgerIcon>

                <MobileMenuDropdown open={isMobileMenuOpen}>
                    <NavLink exact to="/dashboard" activeClassName="active">Home</NavLink>
                    <NavLink to="/activity" activeClassName="active">Transactions</NavLink>
                    {ismember && <NavLink to="/affiliate" activeClassName="active">Referral</NavLink>}
                    <NavLink to="/rank" activeClassName="active">Rank</NavLink>
                    {ismember && <NavLink to="/courses" activeClassName="active">Courses</NavLink>}
                    <NavLink to="/investment" activeClassName="active">Investment</NavLink>
                    <NavLink to="/calculator" activeClassName="active">Calculator</NavLink>
                    <NavLink to="/edit-profile" activeClassName="active">Edit Profile</NavLink>
                    {!ismember && <NavLink to="/subscribe" activeClassName="active" style={{color:"red"}}>Subscribe!</NavLink>}
                    <a>
                        <div
                            onClick={() => {
                                Memory.clear();
                                window.location.href = "/login";
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            Logout
                        </div>
                    </a>
                </MobileMenuDropdown>

                <UserProfile>
                    <img
                        onClick={toggleSubMenu}
                        src="https://cdn4.iconfinder.com/data/icons/glyphs/24/icons_user2-512.png"
                        alt="User Profile"
                    />
                    <div onClick={toggleSubMenu} className="profile-info">
                        <span>{username}</span>
                        <br />
                        {ismember && (
                            <div>
                                <div><small
                                style={{color:"#21AD58", cursor: "pointer",fontSize:13}}
                                onClick={(e) => handleMemberClick(e)}
                            >
                                    MEMBER
                            </small>
                                </div>
                               <div> <span style={{fontSize: 12,color: "red"}}> {membershipExpiry}</span></div>
                            </div>
                        )}
                    </div>

                    {isSubMenuOpen && (
                        <SubMenu>
                            <NavLink onClick={()=>{
                                setSubMenuOpen(false);
                            }} to="/edit-profile">Edit Profile</NavLink>
                            <NavLink onClick={()=>{
                                setSubMenuOpen(false);
                            }} to="/logs">View My Activity</NavLink>
                            <a>
                                <div
                                    onClick={() => {
                                        Memory.clear();
                                        window.location.href = "/login";
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    Logout
                                </div>
                            </a>
                        </SubMenu>
                    )}
                </UserProfile>
            </RightSection>

            {/* Modal for displaying rank and membership expiry */}
            {isModalOpen && (
                <ModalOverlay onClick={closeModal}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <h2>Membership Details</h2>
                        <p>Welcome {username} </p>
                        <p><strong>Rank:</strong> {rank}</p>
                        <p><strong>Membership Expiry:</strong> {membershipExpiry}</p>
                        {ismember && <p><strong>REFERAL CODE:</strong> <a href={"#"} style={{cursor: "pointer"}}
                                                             onClick={handleShare}> {myreferral}</a></p>}
                        <button onClick={closeModal}>Close</button>
                    </ModalContent>
                </ModalOverlay>
            )}
        </MenuBar>
    );
};
