import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { MainMenu } from "./screens/common/MainMenu";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AffiliateScreen from "./screens/referral/AffiliateScreen";
import Dashboard from "./screens/dashboard/Dashboard";
import styled, { createGlobalStyle } from "styled-components";
import MissionScreen from "./screens/mission/MissionScreen";
import RanksScreen from "./screens/rank/RanksScreen";
import CourseScreen from "./screens/course/CourseScreen";
import CompoundInterestCalculator from "./screens/calculator/CompoundInterestCalculator";
import CourseDetails from "./screens/course/CourseDetails";
import ActivityScreen from "./screens/activity/ActivityScreen";
import InvestmentScreen from "./screens/investment/InvestmentScreen";
import LoginScreen from "./screens/login/LoginScreen";
import SignupScreen from "./screens/signup/SignupScreen";
import LegChildScreen from "./screens/referral/LegChildScreen";
import { Memory } from "./core/Memory";
import EditProfileScreen from "./screens/profile/EditProfileScreen";
import PlanSelector from "./screens/plan/PlanSelector";
import PaymentScreen from "./screens/plan/PaymentScreen";
import ForgotPassword from "./screens/forgot/ForgotPassword";
import TopupScreen from "./screens/topup/TopupScreen";
import Footer from "./screens/common/Footer";
import DisclaimerPage from "./screens/common/DisclaimerPage";
import PrivacyPage from "./screens/common/PrivacyPage";
import MultyncomeLanding from "./screens/landing/Landing";
import Resetpassword from "./screens/reset/Resetpassword";
import ChildList from "./screens/referral/ChildList";
import SuccessPayment from "./screens/success/SuccessPayment";
import FailedPayment from "./screens/failed/FailedPayment";
import MyActivity from "./screens/myactivity/MyActivity";
import Partners from "./screens/referral/Partners";  // Assuming Memory utility is imported

// Global Styles for body background
const GlobalStyle = createGlobalStyle`
    body {
        background-color: #000;
    }
`;

// Boxed layout and responsiveness
const DashboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #050505;
    min-height: 100vh;
    padding: 20px;
    color: white;
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const App = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isFront, setIsFront] = useState(true);


    useEffect(() => {
        // Check if the user is logged in from Memory
        const isLoggedIn = Memory.getItem("isloggedin");

        if (isLoggedIn) {
            setIsAuthenticated(true);
            if (location.pathname === "/login" || location.pathname.includes("/reset")  || location.pathname === "/" || location.pathname === "/disclaimer" || location.pathname === "/privacy"  || location.pathname === "/signup" || location.pathname === "/forgot") {
                navigate("/"); // Redirect to dashboard if user is logged in
            }
        } else {
            setIsAuthenticated(false);
            if (location.pathname !== "/login" && !location.pathname.includes("/reset") && location.pathname !== "/"  && location.pathname !== "/disclaimer" && location.pathname !== "/privacy" && location.pathname !== "/signup"  && location.pathname !== "/forgot") {
                navigate("/login"); // Redirect to login if user is not authenticated
            }
        }
    }, [location.pathname, navigate]);



    return (
        <>
            <ToastContainer/>
            {/* Conditionally render the MainMenu and DashboardContainer if not on the login page */}
            {isAuthenticated && <MainMenu />}
            <GlobalStyle />
            {isAuthenticated ? (
                <DashboardContainer>
                    {/* Routing for different screens */}
                    <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/courses/:id" element={<CourseDetails />} />
                        <Route path="/investment" element={<InvestmentScreen />} />
                        <Route path="/activity" element={<ActivityScreen />} />
                        <Route path="/logs" element={<MyActivity />} />
                        <Route path="/calculator" element={<CompoundInterestCalculator />} />
                        <Route path="/rank" element={<RanksScreen />} />
                        <Route path="/courses" element={<CourseScreen />} />
                        <Route path="/success/:transactionId" element={<SuccessPayment />} />
                        <Route path="/failed" element={<FailedPayment />} />

                        <Route path="/affiliate" element={<AffiliateScreen />} />
                        <Route path="/affiliate/:legId" element={<LegChildScreen />} />
                        <Route path="/child/:userId" element={<ChildList />} />
                        <Route path="/mission" element={<MissionScreen />} />
                        <Route path="/edit-profile" element={<EditProfileScreen />} />
                        <Route path="/subscribe" element={<PlanSelector />} />
                        <Route path="/topup" element={<TopupScreen />} />
                        <Route path="/payment/:id" element={ <PaymentScreen/>} />
                        <Route path="/partners" element={<Partners />} />



                    </Routes>
                </DashboardContainer>
            ) : (
                <Routes>
                    <Route path="/login" element={<LoginScreen />} />
                    <Route path="/signup" element={<SignupScreen />} />
                    <Route path="/forgot" element={<ForgotPassword />} />
                    <Route path="/" element={<MultyncomeLanding />} />
                    <Route path="/disclaimer" element={<DisclaimerPage />} />
                    <Route path="/privacy" element={<PrivacyPage />} />
                    <Route path="/reset/:token" element={<Resetpassword />} />
                    <Route path="/resetpassword/:token" element={<Resetpassword />} />

                </Routes>
            )}
            {location.pathname !== "/" && <Footer />}
        </>
    );
};

const AppWrapper = () => {
    return (
        <Router>
            <App />
        </Router>
    );
};

export default AppWrapper;
