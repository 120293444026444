import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaEnvelope, FaPhone, FaLink, FaClipboard, FaUserCircle } from 'react-icons/fa';
import { Utilites } from "../../core/Utilites";
import RequestEngine from "../../core/RequestEngine";
import { useNavigate } from "react-router-dom";

// Styled Components
const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7); /* Dimmed background */
    z-index: 999;
`;

const SideMenuContainer = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    height: 100%;
    background-color: #1c1c28; /* Dark theme background */
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    overflow-y: auto;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #333; /* Dark separator */
`;

const Title = styled.h3`
    font-size: 20px;
    font-weight: bold;
    color: #ffffff; /* White text */
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
`;

const InfoSection = styled.div`
    margin-top: 20px;
`;

const InfoRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    color: #bbbbbb; /* Lighter text color */
`;

const IconContainer = styled.div`
    margin-right: 10px;
    color: #33ff99; /* Accent color for icons */
`;

const Text = styled.span`
    color: #ffffff;
    font-size: 14px;
`;

const LinkText = styled.a`
    color: #33ff99;
    font-size: 14px;
    text-decoration: none;
    &:hover {
        color: #21ad58;
    }
`;

const CopyButton = styled.button`
    background: none;
    border: none;
    color: #33ff99;
    cursor: pointer;
    margin-left: 5px;
`;

const Divider = styled.div`
    margin: 20px 0;
    border-bottom: 1px solid #333;
`;

const SuccessTracker = styled.div`
    margin-top: 20px;
`;

const ProgressBox = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

const ProgressItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #2b2b3d;
    padding: 10px;
    border-radius: 8px;
    width: 45%;
    color: #ffffff;
`;

const ErrorIcon = styled.span`
    color: #ff6666; /* Red for error indicator */
    font-weight: bold;
`;

// Leg Section
const LegSection = styled.div`
    margin-top: 20px;
`;

const LegTitle = styled.h4`
    font-size: 16px;
    font-weight: bold;
    color: #33ff99;
    margin-bottom: 10px;
`;

const LegCard = styled.div`
    background-color: #2b2b3d;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
`;

const ProgressBar = styled.div`
    background-color: #3b3d3b;
    height: 8px;
    border-radius: 5px;
    margin-top: 5px;
    overflow: hidden;
    position: relative;
`;

const Progress = styled.div`
    background-color: ${({ color }) => color};
    height: 100%;
    width: ${({ width }) => width || 0}%;
    transition: width 0.3s ease-in-out;
`;

// Dropdown styling
const Dropdown = styled.select`
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 16px;
    background-color: #1c1c28;
    color: white;
    border: 1px solid #333;
    border-radius: 5px;
`;

const UserSideMenu = ({ user, onClose }) => {
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        Utilites.showSucessMessage("Copied!");
    };

    const [filter, setFilter] = useState("monthly");
    const [progressLeg1, setProgressLeg1] = useState(0);
    const [progressLeg2, setProgressLeg2] = useState(0);
    const [progressLegC, setProgressLegC] = useState(0);
    const [creditLeg1, setCreditLeg1] = useState(0);
    const [creditLeg2, setCreditLeg2] = useState(0);
    const [creditLegC, setCreditLegC] = useState(0);
    const [activeLegs, setActiveLegs] = useState(0);
    const [currentrank, setCurrentrank] = useState("");
    useEffect(() => {
        fetchLegData();
    }, [user,filter]);

    const fetchLegData = async () => {
        let engine = new RequestEngine();
        try {

            const userid =  user.id?user.id:user._id;
            const response = await engine.getItem(`user/legsinfo/${userid}?filter=${filter}`);
            if (response && response.status === 200 && response.data) {
                const legsData = response.data.data;
                setCurrentrank(legsData?.currentrank?.title)

                setProgressLeg1(legsData.legs[0]?.progress || 0);
                setProgressLeg2(legsData.legs[1]?.progress || 0);
                if(isNaN(legsData.legs[2]?.progress )){
                    setProgressLegC( 0);
                }else{
                    setProgressLegC(legsData.legs[2]?.progress || 0);
                }

                debugger
                setCreditLeg1(legsData.legs[0]?.credit || 0);
                setCreditLeg2(legsData.legs[1]?.credit || 0);
                setCreditLegC(legsData.legs[2]?.credit || 0);
                let _activelegs=0;
                if(legsData.legs[0]?.progress!=0){
                    _activelegs++;
                }
                if(legsData.legs[1]?.progress!=0){
                    _activelegs++;
                }
                if(legsData.legs[2]?.progress!=0){
                    _activelegs++;
                }
                setActiveLegs(_activelegs)
            }
        } catch (error) {
            console.error("An error occurred while fetching legs data:", error);
        }
    };

    return (
        <>
            <Backdrop onClick={onClose} />
            <SideMenuContainer>
                <Header>
                    <Title>{user.first_name} {user.last_name}</Title>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                </Header>

                <InfoSection>
                    <InfoRow>
                        <IconContainer><FaUserCircle /></IconContainer>
                        <Text>{currentrank || "Unranked"}</Text>
                    </InfoRow>
                    <InfoRow>
                        <IconContainer><FaUserCircle /></IconContainer>
                        <Text>{user.username}</Text>

                    </InfoRow>
                    <InfoRow>
                        <IconContainer><FaEnvelope /></IconContainer>
                        <Text>{user.email}</Text>
                    </InfoRow>
                    <InfoRow>
                        <IconContainer><FaPhone /></IconContainer>
                        <Text>{user.phone}</Text>
                    </InfoRow>
                </InfoSection>

                <Divider />

                <SuccessTracker>
                    <Title>Success Tracker</Title>
                    <ProgressBox>
                        <ProgressItem>
                            <Text>PC</Text>
                            <Text><ErrorIcon>0</ErrorIcon> / 100</Text>
                        </ProgressItem>
                        <ProgressItem>
                            <Text>DC</Text>
                            <Text><ErrorIcon>0</ErrorIcon> / 1,000</Text>
                        </ProgressItem>
                    </ProgressBox>
                    <ProgressBox>
                        <ProgressItem>
                            <Text>Credit</Text>
                            <Text>{user.credit}</Text>
                        </ProgressItem>
                    </ProgressBox>
                </SuccessTracker>


                <Dropdown value={filter} onChange={(e) => {
                    setFilter(e.target.value);
                }}>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                </Dropdown>

                <LegSection>
                    <LegTitle>Legs</LegTitle>
                    <LegCard>
                        <Text>Leg 1: {creditLeg1} credits</Text>
                        <ProgressBar><Progress width={progressLeg1} color="#33ff99" /></ProgressBar>
                    </LegCard>
                    <LegCard>
                        <Text>Leg 2: {creditLeg2} credits</Text>
                        <ProgressBar><Progress width={progressLeg2} color="#66b3ff" /></ProgressBar>
                    </LegCard>
                    <LegCard>
                        <Text>Leg 3: {creditLegC} credits</Text>
                        <ProgressBar><Progress width={progressLegC} color="#c299ff" /></ProgressBar>
                    </LegCard>
                </LegSection>
            </SideMenuContainer>
        </>
    );
};

export default UserSideMenu;
