import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: white;
    min-height: 70vh;
    text-align: center;
`;

const Title = styled.h1`
    font-size: 32px;
    color: #ff4c4c;
    margin-bottom: 20px;
`;

const Message = styled.p`
    font-size: 18px;
    margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 15px;
`;

const Button = styled.button`
    background-color: #ff4c4c;
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #ff6666;
    }
`;

const FailedPayment = () => {
    const navigate = useNavigate();

    const handleRetry = () => {
        navigate('/payment'); // Redirect to payment page to retry
    };

    const handleSupport = () => {
        navigate('/support'); // Redirect to support or help page
    };

    return (
        <Container>
            <Title>Payment Failed</Title>
            <Message>We’re sorry, but your payment could not be processed.</Message>
            <Message>Please try again or contact support for assistance.</Message>
        </Container>
    );
};

export default FailedPayment;
