const PrivacyPage = () => {
    return (
        <div style={{ padding: "20px", color: "white", backgroundColor: "#000", minHeight: "20vh" }}>
            <h2>Privacy Policy</h2>
            <p>
                Introduction: At Multyncome, we are committed to protecting your privacy. This Privacy Policy explains
                how we collect, use, disclose, and safeguard your information when you use our app, website, and
                services. By using Multyncome, you agree to the collection and use of information as outlined in this
                policy. If you do not agree with this policy, please discontinue using the app.
                Information We Collect We collect various types of information to provide and improve our services:
                <br/>
                Personal Information: When you register or use our services, we may collect personally identifiable
                information, including but not limited to your name, email address, phone number, and payment
                information.
                Usage Data: We automatically collect information about how you access and interact with the app. <br/>
                Cookies and Tracking Data: We may use cookies and similar tracking technologies to monitor your activity
                on our app and collect data to improve user experience. You can control the use of cookies through your
                browser settings. <br/>
                How We Use Your Information We use the collected information for the following purposes:
                To provide and maintain our services: This includes registering your account, processing payments, and
                delivering content and services. <br/>
                To improve our services: We analyze usage data to enhance user experience and improve our app's
                performance. <br/>
                To communicate with you: We may send you updates, promotional content, or important information
                regarding your account. <br/>
                To ensure security: We use your information to detect and prevent fraudulent or unauthorized activity.
                To comply with legal obligations: We may process your data to comply with legal and regulatory
                requirements. <br/>
                Sharing of Information Multyncome does not sell, rent, or trade your personal information to third
                parties. However, we may share your information in the following situations: <br/>
                With service providers: We may share your information with trusted third-party service providers who
                assist us in operating the app, processing payments, or providing services. <br/>
                For legal purposes: We may disclose your information if required to do so by law or in response to valid
                requests by public authorities (e.g., a court or government agency). <br/>
                In business transfers: If Multyncome is involved in a merger, acquisition, or asset sale, your
                information may be transferred as part of that transaction. <br/>
                Data Security We take the security of your data seriously and implement reasonable security measures to
                protect your information from unauthorized access, use, or disclosure. However, no system is completely
                secure, and we cannot guarantee the absolute security of your data. <br/>
                Data Retention We retain your personal information for as long as necessary to fulfill the purposes
                outlined in this Privacy Policy, unless a longer retention period is required by law. When we no longer
                need your data, we will securely delete or anonymize it. <br/>
                Your Data Protection Rights Depending on your location and applicable data protection laws (e.g., GDPR
                or CCPA), you may have the following rights regarding your personal information:
                Right to Access: You can request a copy of the personal data we hold about you. <br/>
                Right to Rectification: You can request that we correct any inaccurate or incomplete information.
                Right to Erasure: You can request the deletion of your personal data under certain circumstances.
                Right to Restrict Processing: You can ask us to limit how we use your data. <br/>
                Right to Data Portability: You can request your data be transferred to another service provider in a
                structured, commonly used format. <br/>
                Right to Object: You can object to the processing of your personal data for marketing purposes or any
                processing based on legitimate interests. <br/>
                To exercise any of these rights, please contact us at support@multyncome.com. We will respond to your
                request within a reasonable timeframe and in accordance with applicable law. <br/>
                International Data Transfers Multyncome operates globally, and your data may be transferred and
                processed in countries other than your own. We ensure that any international transfers comply with
                applicable data protection laws and that your data is treated securely and in accordance with this
                Privacy Policy. <br/>
                Changes to This Privacy Policy We may update this Privacy Policy from time to time. Any changes will be
                posted on this page, and we will notify you via email or through the app if the changes are significant.
                Your continued use of the app after any changes constitutes your acceptance of the revised policy.
                Contact Us If you have any questions or concerns about this Privacy Policy or your data, please click on
                this link: https://www.multyncome.com/Contact_support.html
            </p>
        </div>
    );
};

export default PrivacyPage;
