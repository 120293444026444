import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { FaEye, FaEyeSlash, FaQuestionCircle } from "react-icons/fa";
import helpImage from "../../assets/images/help.jpg"; // Adjust this path based on your structure
import { Utilites } from "../../core/Utilites";
import RequestEngine from "../../core/RequestEngine";
import { useNavigate } from "react-router-dom";

// Styled Components
const TopupContainer = styled.div`
    width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1c1c28;
    border-radius: 10px;
    color: white;

    @media (max-width: 768px) {
        width: 90%;
        padding: 15px;
    }

    @media (max-width: 480px) {
        width: 100%;
        padding: 10px;
    }
`;

const InputWrapper = styled.div`
    position: relative;
    margin-bottom: 20px;
`;

const Input = styled.input`
    width: 94%;
    padding: 15px;
    background-color: #1e1e1e;
    border: 1px solid ${({ error }) => (error ? "red" : "#333")};
    color: white;
    border-radius: 8px;
    font-size: 16px;

    &:focus {
        outline: none;
        border-color: ${({ error }) => (error ? "red" : "#33ff99")};
    }

    @media (max-width: 480px) {
        width: 100%;
        font-size: 14px;
        padding: 12px;
    }
`;

const ToggleButton = styled.span`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: #21ad58;
`;

const HelpIcon = styled(FaQuestionCircle)`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: #21AD58;
`;

const Tooltip = styled.div`
    position: absolute;
    top: -120px;
    left: 100%;
    background-color: #333;
    padding: 10px;
    border-radius: 8px;
    color: white;
    width: 200px;
    z-index: 1000;

    img {
        max-width: 100%;
        border-radius: 8px;
    }

    @media (max-width: 480px) {
        top: -90px;
        left: 50%;
        transform: translateX(-50%);
        width: 150px;
    }
`;

const Button = styled.button`
    width: 100%;
    padding: 15px;
    background-color: #21ad58;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #33ff99;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }

    @media (max-width: 480px) {
        padding: 12px;
        font-size: 16px;
    }
`;

const ButtonLoader = styled.div`
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const StepContainer = styled.div`
    margin-bottom: 20px;
`;

const ErrorMessage = styled.div`
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
`;

const ExplanationText = styled.p`
    color: #ccc;
    font-size: 14px;
    margin-top: 5px;

    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const TopupScreen = () => {
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState({  amount: "", receiptCode: "" });
    const [errors, setErrors] = useState({});
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);
    const [loading, setLoading] = useState(false);  // Loading state for submit button

    // Handle input change
    const handleInputChange = (e, field) => {
        let value = e.target.value;
        if (field === "amount") value = value.replace(/\D/g, ""); // Only numbers for amount
        setInputValues({ ...inputValues, [field]: value });

        if (value) setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
    };

    // Final submit (single step now)
    const handleSubmit = async () => {
        const requiredFields = [ "amount", "receiptCode"];
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!inputValues[field]) newErrors[field] = true;
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);
        try {
            const engine = new RequestEngine();
            const data = {
                amount: inputValues.amount,
                receiptCode: inputValues.receiptCode,
            };

            const response = await engine.postItem("user/topup", data);
            if (response.status === 200 && response.data.success) {
                Utilites.showSucessMessage("Thank you, please wait for admin approval.");
                navigate("/dashboard");
            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        } catch (error) {
            Utilites.showErrorMessage("An error occurred during topup.");
        } finally {
            setLoading(false);  // Stop loading
        }
    };

    // Tooltip for the help icon
    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    };

    // Close tooltip if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setShowTooltip(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <TopupContainer>
            <StepContainer>
                <InputWrapper>
                    <Input
                        type="text"
                        placeholder="Enter Amount"
                        value={inputValues.amount}
                        onChange={(e) => handleInputChange(e, "amount")}
                        error={errors.amount}
                    />
                    {errors.amount && <ErrorMessage>Amount is required</ErrorMessage>}
                </InputWrapper>

                <p>
                    Please send the <strong>{inputValues.amount}</strong> USDT to the following TRC20 address:
                </p>
                <p>
                    <strong>TVGtGtVzEY7e67K3rPQjPgEtZMu9i8ND4e</strong>
                </p>

                <InputWrapper>
                    <Input
                        type="text"
                        placeholder="Enter Transaction/Receipt Code"
                        value={inputValues.receiptCode}
                        onChange={(e) => handleInputChange(e, "receiptCode")}
                        error={errors.receiptCode}
                    />
                    <HelpIcon onClick={toggleTooltip} />
                    {showTooltip && (
                        <Tooltip ref={tooltipRef}>
                            <img src={helpImage} alt="Help" />
                        </Tooltip>
                    )}
                    {errors.receiptCode && <ErrorMessage>Receipt Code is required</ErrorMessage>}
                </InputWrapper>

                {/* Explanation about how to get the transaction code */}
                <ExplanationText>
                    After sending the USDT amount, go to your transaction history, find the transaction, and copy the TXID (transaction ID). Paste it here as the receipt code.
                </ExplanationText>

                <Button onClick={handleSubmit} disabled={loading}>
                    {loading ? <ButtonLoader /> : "Submit"}
                </Button>
            </StepContainer>
        </TopupContainer>
    );
};

export default TopupScreen;
