import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import RequestEngine from "../../core/RequestEngine"; // Assuming RequestEngine is located here

// Container for the entire course screen
const CourseContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
`;

// Grid layout for courses
const CourseGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two-column layout */
    gap: 20px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr; /* Single column for mobile view */
    }
`;

// Course card
const CourseCard = styled.div`
    background-color: #1c1c28;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    cursor: pointer;
    display: flex;
    flex-direction: column; /* Stack the content vertically */

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 5px rgba(0, 255, 153, 0.7);
    }

    &:hover img {
        filter: brightness(1.2);
    }

    &:hover h4 {
        text-shadow: 0 0 5px #33ff99;
    }
`;

// Course image
const CourseImage = styled.img`
    width: 100%;
    height: 250px; /* Adjust the height of the image */
    object-fit: cover;
    transition: filter 0.1s ease-in-out;
`;

// Course content
const CourseContent = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
        margin: 0 0 10px;
        font-size: 18px;
        color: #33ff99;
        transition: text-shadow 0.1s ease-in-out;
    }

    p {
        font-size: 14px;
        color: #ccc;
    }
`;

const CourseScreen = () => {
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]); // State to store the courses
    const [loading, setLoading] = useState(true); // State to show loading status

    // Function to fetch courses from API
    const fetchCourses = async () => {
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem("user/course/list");
            if (response && response.status === 200 && response.data) {
                setCourses(response.data.data); // Store the fetched courses
            } else {
                console.error("Failed to fetch courses:", response.data.message);
            }
        } catch (error) {
            console.error("An error occurred while fetching courses:", error);
        } finally {
            setLoading(false); // Stop the loading spinner
        }
    };

    // Fetch courses when component mounts
    useEffect(() => {
        fetchCourses();
    }, []);

    const handleCourseClick = (id) => {
        navigate(`/courses/${id}`); // Navigate to CourseDetails using course id
    };

    if (loading) {
        return (
            <CourseContainer>
                <h2>Loading Courses...</h2>
            </CourseContainer>
        );
    }

    return (
        <CourseContainer>
            <h2>Available Courses</h2>
            <CourseGrid>
                {courses.map((course) => (
                    <CourseCard key={course.id} onClick={() => handleCourseClick(course.id)}>
                        <CourseImage src={course.fullpicture} alt={course.title} />
                        <CourseContent>
                            <h4>{course.title}</h4>
                            <p>{course.description}</p>
                        </CourseContent>
                    </CourseCard>
                ))}
            </CourseGrid>
        </CourseContainer>
    );
};

export default CourseScreen;
