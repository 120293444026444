const DisclaimerPage = () => {
    return (
        <div style={{ padding: "20px", color: "white", backgroundColor: "#000", minHeight: "20vh" }}>
            <h2>Disclaimer</h2> 
            <p>
                Multyncome is not a financial company or a licensed financial advisor. We provide tools, resources, and educational
                content aimed at helping users enhance their knowledge and develop their skills in business and digital marketing.
                Multyncome does not receive any money directly for investments. All funds intended for investment are managed
                exclusively through licensed and legal brokers connected to our platform. Users should conduct their investments
                through these authorized brokers to ensure compliance with financial regulations.
                Users should be aware that any investment or financial decisions they make are their own responsibility, and it is
                recommended to consult with a professional financial advisor before making any financial or investment decisions.
                Multyncome is not liable for any financial losses or unsuccessful investments resulting from the use of the
                information or tools available through our platform
            </p>
        </div>
    );
};

export default DisclaimerPage;
