import React from 'react'

import './style.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import DsnGrid, {DsnGridProps} from "../DsnGrid";
import {dsnCN} from "../../hooks/helper";
import LazyImg from "../LazyImg";

const BrandDetails = [
    {
        linkText: "100$-2500$",
        title: "50%",
    },
    {
      
        linkText : "2500$-10,000$",
        title: "55%",
    },
    {
  
        linkText: "10,000$ & above",
        title: "60%",
    },
 
]

function BrandClient({className, ...restProps}: DsnGridProps) {

    return (
        <DsnGrid className={dsnCN('wrapper-brand', className)} {...restProps}>
            {BrandDetails.map(
                (item, index) => {
                    return (
                        <div key={index} className="brand-item background-section">
                            <div className="box-img">
                             
                                <div className="entry">
                                        <div>
                                          
                                            <a className='mb-20' href={item.href} target="_blank" rel="noopener noreferrer">
                                                {item.linkText}
                                            </a>
                                            <h3 style={{
                                        color: '#33ff99',
                                    }}>
                                                {item.title}
                                            </h3>
                                        </div>
                                    </div>
                            </div>
                        
                        </div>
                    );
                }
            )}
        </DsnGrid>

    )
}

export default BrandClient
