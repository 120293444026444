import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, useNavigate } from "react-router-dom";
import { Utilites } from "../../core/Utilites";
import RequestEngine from "../../core/RequestEngine";
import logo from "../../assets/images/logo.svg";

// Glowing Effect Animation
const glowingEffect = keyframes`
    0% {
        text-shadow: 0 0 5px #33ff99, 0 0 10px #33ff99, 0 0 20px #33ff99, 0 0 30px #757575;
    }
    50% {
        text-shadow: 0 0 10px #33ff99, 0 0 20px #33ff99, 0 0 30px #33ff99, 0 0 40px #33ff99;
    }
    100% {
        text-shadow: 0 0 5px #33ff99, 0 0 10px #33ff99, 0 0 20px #33ff99, 0 0 30px #33ff99;
    }
`;

// Container for the entire login screen
const LoginContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: #fff;

    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }
`;

// Left section with the logo and background
const LeftSection = styled.div`
    flex: 1;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 20px;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

// Logo container
const Logo = styled.div`
    font-size: 48px;
    font-weight: bold;
    color: white;
    text-align: center;
    span {
        display: block;
        margin-top: 15px;
    }

    @media (max-width: 768px) {
        font-size: 36px;
    }
`;

// Glowing Slogan
const Slogan = styled.div`
    font-size: 28px;
    color: #000000;
    text-align: center;
    animation: ${glowingEffect} 1.5s ease-in-out infinite;

    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

// Glowing line separator
const Separator = styled.div`
    width: 1px;
    background-color: #33ff99;
    box-shadow: 0 0 20px rgba(51, 255, 153, 0.8);
    transition: box-shadow 0.5s ease;

    @media (max-width: 768px) {
        display: none; /* Hide the separator on mobile */
    }
`;

// Right section with the login form
const RightSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: #000000;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

// Login form container
const LoginForm = styled.div`
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 20px;
        max-width: 100%;
    }
`;

const Title = styled.h2`
    margin-bottom: 30px;
    color: #000000;
`;

const InputField = styled.input`
    width: 92%;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid ${({ error }) => (error ? 'red' : '#ddd')};
    border-radius: 5px;
    font-size: 16px;
    outline: none;

    &:focus {
        border-color: ${({ error }) => (error ? 'red' : '#33ff99')};
    }
`;


const ErrorMessage = styled.div`
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
`;

const SignInButton = styled.button`
    width: 100%;
    background-color: #000000;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #45a049;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

const ButtonLoader = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const ForgotPassword = () => {
    const [emailOrUsername, setEmailOrUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ emailOrUsername: false });
    const [errorMessage, setErrorMessage] = useState({ emailOrUsername: '' });
    const navigate = useNavigate();

    useEffect(() => {
        if (emailOrUsername) {
            setError((prev) => ({ ...prev, emailOrUsername: false }));
            setErrorMessage((prev) => ({ ...prev, emailOrUsername: '' }));
        }

    }, [emailOrUsername]);



    const submitForm = async () => {
        let isValid = true;

        setError({ emailOrUsername: false, password: false });
        setErrorMessage({ emailOrUsername: '', password: '' });

        if (!emailOrUsername) {
            setError((prev) => ({ ...prev, emailOrUsername: true }));
            setErrorMessage((prev) => ({ ...prev, emailOrUsername: 'Please enter your email .' }));
            isValid = false;
        }



        if (!isValid) return;

        setLoading(true);

        try {
            let engine = new RequestEngine();
            const data = { email: emailOrUsername };
            const response = await engine.postItem("user/forgot", data);
            setLoading(false);

            if (response && response.status === 200 && response.data.success) {
                Utilites.showSucessMessage("Please Check your email Inbox")
                navigate("/login");
            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        } catch (error) {
            setLoading(false);
            Utilites.showErrorMessage("An error occurred. Please try again.");
        }
    };


    return (
        <LoginContainer>
            {/* Left Section */}
            <LeftSection>
                <Logo>
                    <img
                        src={logo}
                        alt="Logo"
                        width={300}
                    />
                    <Slogan>Money Machine</Slogan>
                </Logo>
            </LeftSection>

            {/* Glowing Separator */}
            <Separator />

            {/* Right Section */}
            <RightSection>
                <LoginForm>
                    <Title>Forgot Password</Title>
                    <p>Please put your email  to reset</p>

                    <InputField
                        type="text"
                        placeholder="Enter your email "
                        value={emailOrUsername}
                        error={error.emailOrUsername}
                        onChange={(e) => setEmailOrUsername(e.target.value)}
                    />
                    {error.emailOrUsername && <ErrorMessage>{errorMessage.emailOrUsername}</ErrorMessage>}


                    <SignInButton onClick={submitForm} disabled={loading}>
                        {loading ? <ButtonLoader /> : 'Submit'}
                    </SignInButton>


                </LoginForm>
            </RightSection>


        </LoginContainer>
    );
};

export default ForgotPassword;
